import { Box, Button, Card, Grid, Typography, useMediaQuery } from "@mui/material";
import {
  ADMIN_LINK,
  checkQuilValidation,
  DATA_TYPE,
  isAdminAdd,
  isAdminEdit,
  renderPlaceholder,
} from "../../../common/contentType/contentTypeData";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Adminwrapper } from "../../../common";
import TypographyWithMore from "../../../common/contentType/TypographyWithMore";
import CustomCardWrapper from "../../../common/contentType/CustomCardWrapper";
import PdfViewer from "../../../PdfViewer";
import { useTheme } from "@mui/styles";

const PDF = ({ content }) => {
  const theme = useTheme();
  const { QUILL, LINK } = DATA_TYPE;
  const { type, name, description } = content?.allContent;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Initialize the state and formik values
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: content?.allContent?.content,
  });

  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };

  const contentFormik = useFormik({
    initialValues: {
      name: data?.name,
      type: data?.type,
      description: data?.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Pdf is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });

  const handlePdfVisibility = () => {
    // return "https://stickball.s3.amazonaws.com/sample.pdf"
    if (!data?.content) return "../assets/img/dummy/pdf.png";
    return data?.content;
  };

  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };

  return (
    <CustomCardWrapper
      bgColor={theme.palette.primary.extraLight}
      position="relative"
      overflowX="hidden"
      overflow="hidden"
    >


      <Grid sx={{ height: "calc(100% + 1px)" }} container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
            <TypographyWithMore
              variant="h2"
              color="primary"
              data={data?.name}
              textLength={10}
              lines={2}
              textAlign="left"
              width="100%"
            />
            <TypographyWithMore
              variant="body1"
              color="text"
              data={data?.description}
              textLength={isMobile ? 15 : 100}
              lines={isMobile ? 2 : 10}
              textAlign="left"
              width="100%"
            />
        </Grid>
        <Grid item xs={12} md={8}>
            {data?.content ? (
              <PdfViewer pdfUrl={handlePdfVisibility()} />
            ) : (
              <img
                src={handlePdfVisibility()}
                alt="Placeholder Image"
                width="500px"
                height="auto"
              />
            )}
        </Grid>
      </Grid>
      <Card
        sx={{
          backgroundColor: theme.palette.primary.main,
          borderRadius: "0rem 0rem 1.8rem 1.8rem",
          boxShadow: "none",
          width: "100%",
          p: "1rem",
          position: "absolute",
          bottom: 0,
          left: 0
        }}
      >
        <Typography variant="h5" fontWeight='600' color={theme.palette.common.white} textAlign='center'>Explore this document</Typography>
      </Card>
    </CustomCardWrapper>
  );
};

export default PDF;
