import {
  ADMIN_LINK,
  checkQuilValidation,
  DATA_TYPE,
  getName,
  isAdminAdd,
  isAdminEdit,
  isValidUrl,
  renderPlaceholder,
} from "../../../common/contentType/contentTypeData";
import { useState, useRef, useCallback, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Adminwrapper, ResizableBoxes } from "../../../common";
import { useStyles } from "../../../../utils/styles";
import TypographyWithMore from "../../../common/contentType/TypographyWithMore";
import CustomCardWrapper from "../../../common/contentType/CustomCardWrapper";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import LazzyLoading from "../../../common/LazzyLoading";
import Webcam from "react-webcam";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import StickbotTop from "../../../common/contentType/StickbotTop";

const RenderStickbotCamera = ({ content, user }) => {
  // const data?.content = JSON.parse(content?.allContent?.content)
  const { QUILL, LINK } = DATA_TYPE;
  const { name, type, description } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    content: content?.allContent?.content,
  });
  const classes = useStyles();
  const theme = useTheme();
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Image is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [timer, setTimer] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    setTimer(0);
    setIsRunning(true);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: isSafari ? "video/mp4" : "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    setIsRunning(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleDownload = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "video.webm";
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1000); // Increase the timer by 10 milliseconds
      }, 1000);
    }

    return () => {
      clearInterval(intervalId); // Clean up the interval on component unmount
    };
  }, [isRunning]);

  const videoConstraints = {
    // // width: "auto",
    // width: "350",
    // height: "350",
    facingMode: "user",
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <Box height={{ sm: "75vh", xs: "100%" }} overflow="auto">
      {isAdminEdit() && (
        <Button
          className={classes.btnUpdateStyle}
          onClick={contentFormik.handleSubmit}
        >
          {" "}
          Update
        </Button>
      )}
      {isAdminAdd() && (
        <Button
          className={classes.btnAddStyle}
          onClick={contentFormik.handleSubmit}
        >
          ADD
        </Button>
      )}
      <Adminwrapper
        errorText={contentFormik.errors.name}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="name"
      >
        {renderPlaceholder(data?.name, "Enter Name")}
        <TypographyWithMore
          variant="h2"
          color="primary"
          data={data?.name}
          textLength={10}
          lines={2}
          textAlign="center"
          width="100%"
        />
      </Adminwrapper>
      <Adminwrapper
        errorText={contentFormik.errors.description}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="description"
      >
        {renderPlaceholder(data?.description, "Enter Description")}
        <TypographyWithMore
          variant="body1"
          color="text"
          data={data?.description}
          textLength={20}
          lines={2}
          textAlign="center"
          width="100%"
        />
      </Adminwrapper>

      <ResizableBoxes height={{ sm: "60vh", xs: "calc(100vh - 270px)" }}>
        <>
          {!isAdminEdit() && !isAdminAdd() && (
            <>
              <Box
                position="relative"
                width="100%"
                borderRadius="1.25rem"
                overflow="hidden"
              >
                <Webcam
                  ref={webcamRef}
                  audio={true}
                  muted={true}
                  mirrored
                  width="100%"
                  videoConstraints={videoConstraints}
                />

                {capturing && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 0.1,
                      position: "absolute",
                      top: "20px",
                      left: "20px",
                      backgroundColor: theme.palette.common.disabled,
                      padding: "1px 5px",
                      borderRadius: "2px",
                    }}
                  >
                    <FiberManualRecordIcon fontSize="small" color="error" />
                    <Typography
                      sx={{
                        color: theme.palette.common.white,
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      REC
                    </Typography>
                  </Box>
                )}

                {/* <Box
                  sx={{
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                  }}
                >
                  <Typography
                    sx={{ color: theme.palette.common.white, fontSize: "14px" }}
                  >
                    {formatTime(timer)}
                  </Typography>
                </Box> */}

                <Box
                  sx={{
                    backgroundColor: capturing && "error",
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                    color: "red",
                    padding: "3px 10px",
                  }}
                >
                  <Typography>{formatTime(timer)}</Typography>
                </Box>
                <Box sx={{ position: "absolute", bottom: "10px", left: "48%" }}>
                  {capturing ? (
                    <IconButton onClick={handleStopCaptureClick} disableRipple>
                      <StopCircleIcon
                        fontSize="large"
                        sx={{ color: theme.palette.error.main }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton onClick={handleStartCaptureClick} disableRipple>
                      <RadioButtonCheckedIcon
                        fontSize="large"
                        sx={{ color: theme.palette.common.white }}
                      />
                    </IconButton>
                  )}
                </Box>
              </Box>
              {recordedChunks.length > 0 && !capturing && (
                <Button
                  onClick={handleDownload}
                  sx={{
                    border: "none",
                    backgroundColor: "#013CA5",
                    padding: "10px 25px",
                    color: theme.palette.common.white,
                    borderRadius: "16px",
                    fontWeight: "600",
                  }}
                >
                  Upload Your Video
                </Button>
              )}
            </>
          )}
        </>

        <>
            <StickbotTop url={isValidUrl(data?.content)
                  ? data?.content + `?userId=${getName(user)}`
                  : "www.google.com"}/>
        </>
      </ResizableBoxes>
    </Box>
  );
};

export default RenderStickbotCamera;
