import { Box, Card, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField, Typography, useTheme, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/effect-cards';
import './style.css';

export default function MobileQuestions({
    questions,
    questionsIndex,
    value,
    handleRadioChange,
    analyticsData,
    lang,
    handleResponse,
    decrementIndex,
    incrementIndex,
    checkFileName,
    inputField,
    descStyle,
}) {
    const swiperRef = useRef(null);
    const theme = useTheme();
    const [showPopup, setShowPopup] = React.useState(!localStorage.getItem('hideSwipeInstructions'));

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleDontShowAgain = () => {
        localStorage.setItem('hideSwipeInstructions', 'true');
        setShowPopup(false);
    };

    const handleSlideChange = (swiper) => {
        const currentIndex = swiper.activeIndex;
        const previousIndex = swiper.previousIndex;

        if (!localStorage.getItem('hideSwipeInstructions')) {
            setShowPopup(true);
        }

        if (currentIndex > previousIndex) {
            incrementIndex(questionsIndex);
        } else if (currentIndex <= previousIndex) {
            decrementIndex(questionsIndex);
        }
    };
    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.swiper.update(); // Refresh the swiper when data changes
        }
    }, [questions, swiperRef]);
    return (
        <>
            <Dialog
                open={showPopup}
                sx={{
                    '& .MuiDialog-paper': {
                        borderRadius: '2rem',
                        padding: '1rem',
                        position: 'relative'
                    },
                    zIndex: 10000
                }}
            >
                <IconButton
                    onClick={handleClosePopup}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Typography>
                        Select an answer, then swipe right to continue
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDontShowAgain} variant="text">
                        Don't show again
                    </Button>
                </DialogActions>
            </Dialog>
            <Swiper
                ref={swiperRef}
                effect={'cards'}
                grabCursor={!((value.length == questionsIndex) || (questions[questionsIndex]?.isFreeText && analyticsData[questionsIndex]?.answer?.split(" ")?.length < 10))}
                modules={[EffectCards]}
                className="mySwiper"
                allowSlideNext={!((value.length == questionsIndex) || (questions[questionsIndex]?.isFreeText && analyticsData[questionsIndex]?.answer?.split(" ")?.length < 10))}
                onSlideChange={handleSlideChange}
                onSliderFirstMove={(swiper) => {
                    const touchedDirection = swiper?.touchesDirection
                    if (questionsIndex == questions?.length - 1 && (value.length == questions.length) && touchedDirection == "next") {
                        handleResponse()
                    }
                }}
                style={{ zIndex: 1 }}
            >
                {
                    questions?.map((question, index) => (
                        <SwiperSlide key={question?.id}>
                            <Card
                                sx={{
                                    backgroundColor: theme.palette.common.dialougeBg,
                                    borderRadius: "1.8rem",
                                    border: { sm: `1px solid ${theme.palette.common.disabled}`, xs: "none" },
                                    boxShadow: "none",
                                    width: "`100%",
                                    height: { md: "calc(100vh - 150px)", xs: "calc(100vh - 200px)" },
                                    p: "1.5rem",
                                    position: "relative",
                                }}
                            >
                                <Card
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        borderRadius: ".8rem",
                                        boxShadow: "none",
                                        width: "`100%",
                                        p: ".5rem",
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        color={theme.palette.common.white}
                                        textAlign="center"
                                    >
                                        {question?.section}
                                    </Typography>
                                </Card>
                                <Card
                                    sx={{
                                        backgroundColor: theme.palette.common.white,
                                        borderRadius: "1.3rem",
                                        boxShadow: "none",
                                        height: "95vh",
                                        overflow: "auto",
                                        width: "100%",
                                        p: "1rem",
                                        mt: "1rem",
                                    }}
                                >
                                    {questions && (
                                        <Box>
                                            {question?.isFreeText ? (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        mb: 3,
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <Box display="flex" gap={1}>
                                                        <Typography
                                                            fontSize={{ xs: "12px", md: "18px" }}
                                                            color="text"
                                                        >
                                                            {index + 1}.
                                                        </Typography>
                                                        <Typography
                                                            fontSize={{ xs: "12px", md: "18px" }}
                                                            fontWeight="700"
                                                            color="text"
                                                        >
                                                            {question?.questionText}
                                                        </Typography>
                                                    </Box>

                                                    <TextField
                                                        label="Write Your Answer (at least 10 words)"
                                                        required
                                                        variant="outlined"
                                                        sx={inputField}
                                                        multiline
                                                        fullWidth
                                                        rows={8}
                                                        FormHelperTextProps={{ style: { textAlign: 'right', marginRight: 10 } }}
                                                        error={analyticsData[questionsIndex]?.answer?.split(" ")?.length < 10}
                                                        helperText={`${analyticsData[index]?.answer?.split(" ")?.length || 0}/10`}
                                                        placeholder={
                                                            lang === "sp"
                                                                ? "Escribe tu respuesta"
                                                                : "Write Your Answer"
                                                        }
                                                        onChange={(e) => {
                                                            handleRadioChange(
                                                                e.target.value,
                                                                index,
                                                                true
                                                            );
                                                        }}
                                                    />
                                                </Box>
                                            ) : (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: { xs: "column", md: "row" },
                                                        gap: 1,
                                                    }}
                                                >
                                                    <Box
                                                        width={{
                                                            xs: "100%",
                                                            md: question?.questionImage
                                                                ? "50%"
                                                                : "100%",
                                                        }}
                                                    >
                                                        <Box display="flex" gap={1}>
                                                            <Typography
                                                                fontSize={{ xs: "12px", md: "18px" }}
                                                                color="text"
                                                            >
                                                                {index + 1}.
                                                            </Typography>
                                                            <Typography
                                                                fontSize={{ xs: "12px", md: "18px" }}
                                                                fontWeight="700"
                                                                color="text"
                                                            >
                                                                {question?.questionText}
                                                            </Typography>
                                                        </Box>

                                                        <RadioGroup
                                                            aria-label={index}
                                                            id={"question " + (index + 1)}
                                                            name={"question " + (index + 1)}
                                                            value={value[index]?.opt}
                                                            onChange={(e) => {
                                                                handleRadioChange(
                                                                    e.target.value,
                                                                    index,
                                                                    false
                                                                );
                                                            }}
                                                            sx={{ ...descStyle, padding: "6px" }}
                                                        >
                                                            <Box>
                                                                {question?.options?.map(
                                                                    (op, j) => (
                                                                        <Box key={j}>
                                                                            <FormControlLabel
                                                                                sx={{
                                                                                    color: "text",
                                                                                    "& span": {
                                                                                        fontSize: {
                                                                                            md: "14px",
                                                                                            xs: "10px",
                                                                                        },
                                                                                        // p: {
                                                                                        //   xs: "2px",
                                                                                        //   md: "6px",
                                                                                        // },
                                                                                    },
                                                                                }}
                                                                                value={op.id}
                                                                                control={
                                                                                    <Radio
                                                                                        // defaultChecked={
                                                                                        //   value[j]?.opt == op.id
                                                                                        // }
                                                                                        sx={{
                                                                                            color:
                                                                                                theme.palette.primary.light,
                                                                                            "&.Mui-checked": {
                                                                                                color:
                                                                                                    theme.palette.primary.main,
                                                                                            },
                                                                                            "& .MuiSvgIcon-root": {
                                                                                                fontSize: {
                                                                                                    xs: "15px",
                                                                                                    md: "26px",
                                                                                                },
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                label={op.optionText}
                                                                            />
                                                                        </Box>
                                                                    )
                                                                )}
                                                            </Box>
                                                        </RadioGroup>
                                                    </Box>
                                                    {question?.questionImage && (
                                                        <Box width={{ xs: "100%", md: "50%" }}>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                {checkFileName == "mp4" ||
                                                                    checkFileName === "m4v" ? (
                                                                    <Box
                                                                        sx={{
                                                                            width: "100%",
                                                                            maxHeight: "70vh",
                                                                            aspectRatio: "1/1",
                                                                        }}
                                                                    >
                                                                        <video
                                                                            className="google-video"
                                                                            controls
                                                                            style={{
                                                                                width: "90%",
                                                                                height: "100%",
                                                                                objectFit: "contain",
                                                                                borderRadius: "1rem",
                                                                            }}
                                                                        >
                                                                            <source
                                                                                src={
                                                                                    question
                                                                                        ?.questionImage
                                                                                }
                                                                                type="video/mp4"
                                                                            ></source>
                                                                        </video>
                                                                    </Box>
                                                                ) : (
                                                                    <Box
                                                                        sx={{
                                                                            width: "100%",
                                                                            maxHeight: "70vh",
                                                                            aspectRatio: "1/1",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            style={{
                                                                                width: "90%",
                                                                                height: "100%",
                                                                                objectFit: "contain",
                                                                                borderRadius: "1rem",
                                                                            }}
                                                                            src={
                                                                                question
                                                                                    ?.questionImage
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                                </Card>
                                <Box
                                    sx={{
                                        display: { xs: "block", md: "none" },
                                        flexDirection: "column",
                                        width: "90%",
                                        gap: 0.5,
                                        position: "absolute",
                                        bottom: 50,
                                    }}
                                >
                                    <LinearProgress
                                        variant="determinate"
                                        value={((index + 1) * 100) / questions?.length}
                                        sx={{
                                            height: 5,
                                            borderRadius: 5,
                                            backgroundColor: theme.palette.common.white,
                                            border: { sm: `1px solid ${theme.palette.common.disabled}`, xs: "none" },
                                            "& .MuiLinearProgress-bar": {
                                                backgroundColor: theme.palette.success.main,
                                            },
                                        }}
                                    />
                                    <Typography color="text" textAlign="center" fontSize="10px">
                                        {`${index + 1}/${questions?.length}`}
                                    </Typography>
                                </Box>
                            </Card>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </>
    )
}
