import React, { useState } from "react";
import ReactPlayer from "react-player";
import {
  Button,
  Box,
  TextField,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Adminwrapper, ResizableBoxes, TypographyWithMore } from "../../../common";
import {
  ADMIN_LINK,
  checkQuilValidation,
  DATA_TYPE,
  isAdminAdd,
  isAdminEdit,
  renderPlaceholder,
  getName,
  isValidUrl,
  LazzyLoading,
} from "../../../common/contentType/contentTypeData";
import { useStyles } from "../../../../utils/styles";
import StickbotTop from "../../../common/contentType/StickbotTop";


const WireframeStickbot = ({ content, user }) => {
  const classes = useStyles()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const myOBJ = JSON.parse(content?.allContent?.content);
  const [activeLink, setActiveLink] = useState(1);
  const { QUILL, LINK, MEDIA } = DATA_TYPE;
  const { name, description, type } = content?.allContent;
  const [isAdd, setIsAdd] = useState(false);
  const [stickbotName, setStickbotName] = useState("");
  const [stickbotLink, setStickbotLink] = useState("");
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    content: myOBJ,
    wireframe_link: myOBJ.wireframe_link,
    wireframe_link1: myOBJ.skill_assignment,
  });
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
      wireframe_link: data.wireframe_link,
      wireframe_link1: data.wireframe_link1,
    },
    validationSchema: Yup.object({
      // name: Yup.string().required("Name is Required").matches(/(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)|<br>/g, "Name is Required matches"),
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.object().required("Add at least one stickbot"),
      wireframe_link: Yup.string().required("Please enter link here"),
      wireframe_link1: Yup.string(),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <>
      {isAdminEdit() && (
        <Button className={classes.btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </Button>
      )}
      {isAdminAdd() && (
        <Button className={classes.btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </Button>
      )}
      {isAdminAdd() && (
        <>
          {!isAdd && (
            <Button
              sx={{ m: 1, width: "fit-content" }}
              variant="contained"
              onClick={() => setIsAdd(true)}
            >
              Add Stickbot
            </Button>
          )}
          {isAdd && (
            <div>
              <TextField
                placeholder="Name"
                variant="outlined"
                label="Name"
                style={{ width: "400px" }}
                onChange={(e) => setStickbotName(e.target.value)}
              />
              <TextField
                placeholder="Link"
                variant="outlined"
                label="Link"
                style={{ width: "400px" }}
                onChange={(e) => setStickbotLink(e.target.value)}
              />
              <Button
                sx={{ m: 1 }}
                variant="contained"
                onClick={() => {
                  let tempStickbots = data?.content || [];
                  if (!tempStickbots?.link_1_name) {
                    tempStickbots.link_1_name = stickbotName;
                    tempStickbots.link_1 = stickbotLink;
                  } else if (!tempStickbots?.link_2_name) {
                    tempStickbots.link_2_name = stickbotName;
                    tempStickbots.link_2 = stickbotLink;
                  } else if (!tempStickbots?.link_3_name) {
                    tempStickbots.link_3_name = stickbotName;
                    tempStickbots.link_3 = stickbotLink;
                  } else if (!tempStickbots?.link_4_name) {
                    tempStickbots.link_4_name = stickbotName;
                    tempStickbots.link_4 = stickbotLink;
                  } else {
                    tempStickbots.link_5_name = stickbotName;
                    tempStickbots.link_5 = stickbotLink;
                  }
                  setIsAdd(false);

                  setData(...(data.content = tempStickbots));
                }}
              >
                Save
              </Button>
            </div>
          )}
        </>
      )}
      {data?.type === "Stickbot-Wireframe" ? (
        <ResizableBoxes height={{sm: "70vh", xs: "95vh"}}>
          <ResizableBoxes.Left>
            {/* <Box className={classes.stickbotButtons}>
              <Button variant="contained" onClick={() => setActiveLink(1)}>
                {data?.content?.link_1_name}
              </Button>
              {!!data?.content?.link_2_name && (
                <Button variant="contained" onClick={() => setActiveLink(2)}>
                  {data?.content?.link_2_name}
                </Button>
              )}
              {!!data?.content?.link_3_name && (
                <Button variant="contained" onClick={() => setActiveLink(3)}>
                  {data?.content?.link_3_name}
                </Button>
              )}
              {!!data?.content?.link_4_name && (
                <Button variant="contained" onClick={() => setActiveLink(4)}>
                  {data?.content?.link_4_name}
                </Button>
              )}
              {!!data?.content?.link_5_name && (
                <Button variant="contained" onClick={() => setActiveLink(5)}>
                  {data?.content?.link_5_name}
                </Button>
              )}
            </Box> */}
            <Adminwrapper
              errorText={contentFormik.errors.content}
              dataType={LINK}
              data={data}
              setData={setData}
              type="multilinks"
              gridIndex={activeLink}
              label={"Iframe Link"}
            >

<StickbotTop url={ activeLink === 1
                    ? isValidUrl(data?.content?.link_1)
                    ? data?.content?.link_1 + `?userId=${getName(user)}`
                    : ""
                    : activeLink === 2
                    ? isValidUrl(data?.content?.link_2)
                        ? data?.content?.link_2 + `?userId=${getName(user)}`
                        : ""
                      : activeLink === 3
                        ? isValidUrl(data?.content?.link_3)
                          ? data?.content?.link_3 + `?userId=${getName(user)}`
                          : ""
                        : activeLink === 4
                        ? isValidUrl(data?.content?.link_4)
                            ? data?.content?.link_4 + `?userId=${getName(user)}`
                            : ""
                          : isValidUrl(data?.content?.link_5)
                            ? data?.content?.link_5 + `?userId=${getName(user)}`
                            : ""}
                            title={data?.content?.link_1_name}
                            />

{/* {!isIframeReady && <LazzyLoading />} */}
              {/* <iframe
                onLoad={() => setIsIframeReady(true)}
                style={{ height: "85%", width: "100%", opacity: isIframeReady ? 1 : 0 }}
                src={
                  activeLink === 1
                    ? isValidUrl(data?.content?.link_1)
                      ? data?.content?.link_1 + `?userId=${getName(user)}`
                      : ""
                    : activeLink === 2
                      ? isValidUrl(data?.content?.link_2)
                        ? data?.content?.link_2 + `?userId=${getName(user)}`
                        : ""
                      : activeLink === 3
                        ? isValidUrl(data?.content?.link_3)
                          ? data?.content?.link_3 + `?userId=${getName(user)}`
                          : ""
                        : activeLink === 4
                          ? isValidUrl(data?.content?.link_4)
                            ? data?.content?.link_4 + `?userId=${getName(user)}`
                            : ""
                          : isValidUrl(data?.content?.link_5)
                            ? data?.content?.link_5 + `?userId=${getName(user)}`
                            : ""
                }
                frameBorder="0"
              ></iframe> */}
            </Adminwrapper>

          </ResizableBoxes.Left>
          <ResizableBoxes.Right>
            <Adminwrapper
              errorText={contentFormik.errors.name}
              dataType={QUILL}
              data={data}
              setData={setData}
              type="name"
            >
              {renderPlaceholder(data?.name, "Enter Name")}
              {/* <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} /> */}
              <TypographyWithMore textLength={8} lines={2} variant="h2" color="text" data={data?.name} />
            </Adminwrapper>
            <Adminwrapper
              errorText={contentFormik.errors.description}
              dataType={QUILL}
              data={data}
              setData={setData}
              type="description"
            >
              {renderPlaceholder(data?.description, "Enter Description")}
              {/* <QuillEditor
                sx={quilStyle}
                value={data?.description}
                readOnly={true}
              /> */}
              <TypographyWithMore textLength={25} lines={5} variant="body1" color="text" data={data?.description} />
            </Adminwrapper>
            <Adminwrapper
              errorText={contentFormik.errors.wireframe_link1}
              dataType={QUILL}
              data={data}
              setData={setData}
              type="link2"
            >
              {renderPlaceholder(data?.wireframe_link1, "Add Skill Assignment")}
              {/* <QuillEditor
                sx={quilStyle}
                value={data.wireframe_link1}
                readOnly={true}
              /> */}
              <TypographyWithMore textLength={25} lines={5} variant="body1" color="text" data={data?.wireframe_link1} />
            </Adminwrapper>
            <Adminwrapper
              errorText={contentFormik.errors.wireframe_link}
              dataType={LINK}
              data={data}
              setData={setData}
              type="link1"
              label={"Iframe Link"}
            >
              <StickbotTop url={  isValidUrl(data?.wireframe_link)
                    ? data?.wireframe_link +
                    "?token=" +
                    localStorage.getItem("token") +
                    "&isIframe=true"
                    : "https://www.google.com"}/>


              {/* <iframe
                style={{ width: "100%", height: 300 }}
                className="i-frame"
                src={
                  isValidUrl(data?.wireframe_link)
                    ? data?.wireframe_link +
                    "?token=" +
                    localStorage.getItem("token") +
                    "&isIframe=true"
                    : "https://www.google.com"
                }
                frameBorder="0"
              ></iframe> */}
            </Adminwrapper>
          </ResizableBoxes.Right>
        </ResizableBoxes >
      ) : (
        <ResizableBoxes height={{sm: "70vh", xs: "95vh"}}>
          <ResizableBoxes.Left>
            <Adminwrapper
              errorText={contentFormik.errors.name}
              dataType={QUILL}
              data={data}
              setData={setData}
              type="name"
            >
              {renderPlaceholder(data?.name, "Enter Name")}
              {/* <QuillEditor
                  sx={quilStyle}
                  value={data?.name}
                  readOnly={true}
                /> */}
              <TypographyWithMore textLength={8} lines={2} variant="h2" color="text" data={data?.name} />
            </Adminwrapper>
            <Adminwrapper
              errorText={contentFormik.errors.description}
              dataType={QUILL}
              data={data}
              setData={setData}
              type="description"
            >
              {renderPlaceholder(data?.description, "Enter Description")}
              {/* <QuillEditor
                  sx={quilStyle}
                  value={data?.description}
                  readOnly={true}
                /> */}
              <TypographyWithMore textLength={25} lines={5} variant="body1" color="text" data={data?.description} />
            </Adminwrapper>

            {data?.type === "Wireframe-Stickbot" ? (
              <>
                <Adminwrapper
                  errorText={contentFormik.errors.wireframe_link}
                  dataType={LINK}
                  data={data}
                  setData={setData}
                  type="link1"
                  label={"'Iframe Link"}
                >
                   <StickbotTop url={  isValidUrl(data?.wireframe_link)
                        ? data?.wireframe_link +
                        "?token=" +
                        localStorage.getItem("token") +
                        "&isIframe=true"
                        : "www.google.com"}/>
                  {/* {!isIframeReady && <LazzyLoading />}
                  <iframe
                    onLoad={() => setIsIframeReady(true)}
                    style={{
                      opacity: isIframeReady ? 1 : 0,
                      height: "100%",
                      width: "100%"
                    }}
                    className="i-frame"
                    src={
                      isValidUrl(data?.wireframe_link)
                        ? data?.wireframe_link +
                        "?token=" +
                        localStorage.getItem("token") +
                        "&isIframe=true"
                        : "www.google.com"
                    }
                    frameBorder="0"
                  ></iframe> */}
                </Adminwrapper>

                <Adminwrapper
                  errorText={contentFormik.errors.wireframe_link1}
                  dataType={QUILL}
                  data={data}
                  setData={setData}
                  type="link2"
                >
                  {renderPlaceholder(
                    data?.wireframe_link1,
                    "Add Skill Assignment"
                  )}
                  {/* <QuillEditor
                    sx={quilStyle}
                    value={data?.wireframe_link1}
                    readOnly={true}
                  /> */}

                  <TypographyWithMore textLength={25} lines={5} variant="body1" color="text" data={data?.wireframe_link1} />
                </Adminwrapper>
              </>
            ) : (
              <Adminwrapper
                errorText={contentFormik.errors.wireframe_link}
                dataType={LINK}
                data={data}
                setData={setData}
                type="link1"
                label={"Youtube Link"}
              >
                <ReactPlayer
                  width="100%"
                  style={{ height: "fit-content", marginTop: "14px" }}
                  url={data?.wireframe_link}
                  playing={isMobile ? false : true}
                  loop={true}
                  controls={true}
                />
              </Adminwrapper>
            )}

          </ResizableBoxes.Left>

          <ResizableBoxes.Right>
            {/* <Box className={classes.stickbotButtons}>
              <Button variant="contained" onClick={() => setActiveLink(1)}>
                {data?.content?.link_1_name}
              </Button>
              {!!data?.content?.link_2_name && (
                <Button variant="contained" onClick={() => setActiveLink(2)}>
                  {data?.content?.link_2_name}
                </Button>
              )}
              {!!data?.content?.link_3_name && (
                <Button variant="contained" onClick={() => setActiveLink(3)}>
                  {data?.content?.link_3_name}
                </Button>
              )}
              {!!data?.content?.link_4_name && (
                <Button variant="contained" onClick={() => setActiveLink(4)}>
                  {data?.content?.link_4_name}
                </Button>
              )}
              {!!data?.content?.link_5_name && (
                <Button variant="contained" onClick={() => setActiveLink(5)}>
                  {data?.content?.link_5_name}
                </Button>
              )}
            </Box> */}
            <Adminwrapper
              errorText={contentFormik.errors.content}
              dataType={LINK}
              data={data}
              setData={setData}
              type="multilinks"
              gridIndex={activeLink}
              label={"Stickbot-Wireframe Link"}
            >
   <StickbotTop url={   activeLink === 1
                    ? isValidUrl(data?.content?.link_1)
                      ? data?.content?.link_1 + `?userId=${getName(user)}`
                      : ""
                    : activeLink === 2
                      ? isValidUrl(data?.content?.link_2)
                        ? data?.content?.link_2 + `?userId=${getName(user)}`
                        : ""
                      : activeLink === 3
                        ? isValidUrl(data?.content?.link_3)
                          ? data?.content?.link_3 + `?userId=${getName(user)}`
                          : ""
                        : activeLink === 4
                          ? isValidUrl(data?.content?.link_4)
                            ? data?.content?.link_4 + `?userId=${getName(user)}`
                            : ""
                          : isValidUrl(data?.content?.link_5)
                            ? data?.content?.link_5 + `?userId=${getName(user)}`
                            : ""}
                            title={data?.content?.link_1_name}
                            />

              {/* {!isIframeReady && <LazzyLoading />}
              <iframe
                onLoad={() => setIsIframeReady(true)}
                style={{
                  width: "100%",
                  opacity: isIframeReady ? 1 : 0,
                  height: "93%",
                }}
                src={
                  activeLink === 1
                    ? isValidUrl(data?.content?.link_1)
                      ? data?.content?.link_1 + `?userId=${getName(user)}`
                      : ""
                    : activeLink === 2
                      ? isValidUrl(data?.content?.link_2)
                        ? data?.content?.link_2 + `?userId=${getName(user)}`
                        : ""
                      : activeLink === 3
                        ? isValidUrl(data?.content?.link_3)
                          ? data?.content?.link_3 + `?userId=${getName(user)}`
                          : ""
                        : activeLink === 4
                          ? isValidUrl(data?.content?.link_4)
                            ? data?.content?.link_4 + `?userId=${getName(user)}`
                            : ""
                          : isValidUrl(data?.content?.link_5)
                            ? data?.content?.link_5 + `?userId=${getName(user)}`
                            : ""
                }
                frameBorder="0"
              ></iframe> */}
            </Adminwrapper>
          </ResizableBoxes.Right>
        </ResizableBoxes>
      )}
    </>
  );
};

export default WireframeStickbot