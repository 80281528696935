import { useState } from "react";
import {
    ADMIN_LINK,
    checkQuilValidation,
    DATA_TYPE,
    isAdminAdd,
    isAdminEdit,
    renderPlaceholder,
} from "../../../common/contentType/contentTypeData";
import { useFormik } from "formik";
import { useTheme } from "@mui/material/styles";
import { Box, Button, useMediaQuery } from "@mui/material";
import { Adminwrapper, TypographyWithMore } from "../../../common";
import * as Yup from "yup";
import { useStyles } from "../../../../utils/styles";

const RenderImage = ({ content }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles()
    const { QUILL, MEDIA } = DATA_TYPE;
    const { type, name, description } = content?.allContent;
    const [data, setStateData] = useState({
        type,
        name,
        description,
        content: content?.allContent.content,
    });

    const setData = (obj) => {
        setStateData(obj);
        contentFormik.setValues(obj);
    };
    const contentFormik = useFormik({
        initialValues: {
            name: data.name,
            type: data.type,
            description: data.description,
            content: data?.content,
        },
        validationSchema: Yup.object({
            // name: Yup.string().required("Name is Required").matches(/(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)|<br>/g, "Name is Required matches"),
            name: Yup.string()
                .required("Name is Required")
                .test("matches", "Name is required", checkQuilValidation),
            description: Yup.string()
                .required("Description is Required")
                .test("matches", "Description is required", checkQuilValidation),
            content: Yup.mixed().required("Image is Required"),
        }),
        onSubmit: () => {
            handleAdminUpdate();
        },
    });

    const handleImageVisibility = () => {
        if (!data?.content) return "../assets/img/dummy/img.jpeg";
        if (!data?.content.name) return data?.content;
        return URL.createObjectURL(data?.content);
    };

    const handleAdminUpdate = () => {
        window.parent.postMessage(
            isAdminAdd() ? { ...data, points: content?.points } : data,
            ADMIN_LINK
        );
    };

    return (
        <Box sx={{
            flexDirection: { md: "row", xs: "column" },
            width: "100%",
            display: 'flex',
            justifyContent: "center",
            mt: "50px",
            maxHeight: {sm: "80vh", xs: "95vh"},
            overflowY: "auto",
            p: 2,
            borderRadius: "1.8rem",
            border: { sm: `1px solid ${theme.palette.common.disabled}`, xs: "none" }
        }}>
            {isAdminAdd() && (
                <Button className={classes.btnAddStyle} onClick={contentFormik.handleSubmit}>
                    ADD
                </Button>
            )}
            {isAdminEdit() && (
                <Button className={classes.btnUpdateStyle} onClick={contentFormik.handleSubmit}>
                    Update
                </Button>
            )}

            <Box sx={{ width: { md: "25%", xs: "100%" }, height: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                <Adminwrapper
                    errorText={contentFormik.errors.name}
                    dataType={QUILL}
                    data={data}
                    setData={setData}
                    type="name"
                >
                    {renderPlaceholder(data?.name, "Enter Name")}
                    {/* <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} /> */}
                    <TypographyWithMore textAlign="left" textLength={10} lines={2} variant="h2" color="primary" data={data?.name} />
                </Adminwrapper>
                <Adminwrapper
                    errorText={contentFormik.errors.description}
                    dataType={QUILL}
                    data={data}
                    setData={setData}
                    type="description"
                >
                    {renderPlaceholder(data?.description, "Enter Description")}
                    {/* <QuillEditor
                    sx={quilStyle}
                    value={data?.description}
                    readOnly={true}
                /> */}
                    <TypographyWithMore textAlign="left" textLength={150} lines={10} variant="body1" color="text" data={data?.description} />
                </Adminwrapper>
            </Box>
            <Box sx={{ width: { md: "75%", xs: "100%" }, height: "100%", display: "flex", flexDirection: "column" }}>
                <Adminwrapper
                    errorText={contentFormik.errors.content}
                    dataType={MEDIA}
                    data={data}
                    setData={setData}
                    mediaType={"IMAGE"}
                    type="content"
                >
                    <img style={{ height: !isMobile && "43vh", width: "100%", objectFit: "contain" }} src={handleImageVisibility()} alt="" />
                </Adminwrapper>
            </Box>
            {/* <Box className={classes.flexColCenter} sx={{ maxWidth: { sm: "70%", xs: "95%" }, gap: "20px" }}>


            </Box> */}
        </Box>
    );
};

export default RenderImage