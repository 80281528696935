import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Drawer,
    styled,
    Button,
    IconButton,
    Typography,
    useMediaQuery
} from "@mui/material";
import { useQuery } from 'react-query';
import StickbotTop from './contentType/StickbotTop';
import NoAssignments from './IconsSvg/NoAssignments';
import { useStyles } from '../../utils/styles';
import { getAssignments } from '../_api/skilll-assignments';
import { isStatic } from './isStatic';
import ChatBot from './chatbot';
import GoalsModal from './GoalsModal';
import Notes from '../lessons/notes/Notes';
import StaticNotes from '../lessons/notes/StaticNotes';
import { useTheme } from '@mui/material/styles';
import StaticTodoList from '../lessons/notes/StaticTodoList';
import TodoList from '../lessons/notes/time-tracker/TodoList';
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const drawerWidth = "90%";
const StyledDrawer = styled(Drawer)(({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
        width: drawerWidth,
        boxSizing: "border-box",
        "&::-webkit-scrollbar": {
            width: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
        },
        [theme.breakpoints.down("sm")]: {
            height: "85%",
            width: "100%",
        },
    },

}));
function SkillAssignment() {
    const location = useLocation()
    const path = location.pathname
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

    const [openTodoModal, setOpenTodoModal] = useState(false);

    const getAssignmentType = () => {
        if (path.includes('/manage-budget')) {
            return 'manage-budget';
        } else if (path.includes('/boost')) {
            return 'boost';
        } else if (path.includes('/net-worth')) {
            return 'net-worth';
        } else if (path.includes('/credit-score')) {
            return 'credit-score';
        } else if (path.includes('/financial-goals')) {
            return 'financial-goals';
        } else if (path.includes('/taxes')) {
            return 'taxes';
        }
        return 'all';
    };

    const { data: skillAssignments, isLoading } = useQuery(['skillAssignments', getAssignmentType()], () => getAssignments(getAssignmentType()));
    const classes = useStyles()
    const [open, setOpen] = useState(false);
    const [selectedChat, setSelectedChat] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };
    const openModal = () => {
        setShowModal(!showModal);
        setOpenTodoModal(false)
    };
    return (
        <>
            <Box
                sx={{ right: open ? drawerWidth : 0, display: { xs: "none", sm: "block" } }}
                className={classes.skillAssignmentButton} onClick={handleDrawerToggle}>
                {
                    open ? <ArrowForwardIosRoundedIcon sx={{ color: "white" }} /> : <ArrowBackIosNewRoundedIcon sx={{ color: "white" }} />
                }
            </Box>
            {
                isMobile &&
                <Box
                    sx={{ bottom: open ? "85%" : 0, display: { xs: "flex", sm: "none" } }}
                    className={classes.skillAssignmentButtonMobile} onClick={handleDrawerToggle}>
                    {
                        open ? <KeyboardArrowDownRoundedIcon sx={{ color: "white", fontSize: "30px" }} /> : <KeyboardArrowUpRoundedIcon sx={{ color: "white", fontSize: "30px" }} />
                    }
                </Box>
            }
            <StyledDrawer
                anchor={isMobile ? "bottom" : "right"}
                variant="temporary"
                open={open}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <Box
                    sx={{
                        padding: 1,
                        px: 3,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: ".5rem",
                        height: "100%",
                        overflow: 'auto'
                    }}
                >
                    {selectedChat ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {selectedChat.type === 'image' ? (
                                <img src={selectedChat.iframeLink} alt="Chat content" style={{ width: '100%', height: 'auto' }} />
                            ) : selectedChat.type === 'video' ? (
                                <video controls style={{ width: '100%', height: 'auto' }}>
                                    <source src={selectedChat.iframeLink} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : selectedChat.type === 'stickbot' ? (
                                <StickbotTop url={selectedChat.iframeLink} />
                            )
                                : <NoAssignments />
                            }
                            <Button
                                startIcon={<ArrowBackRoundedIcon />}
                                variant="contained"
                                onClick={() => setSelectedChat(null)}
                                sx={{ mt: 4 }}
                            >
                                Back
                            </Button>
                        </Box>
                    ) : (
                        <>
                            {
                                !!skillAssignments?.length &&
                                <Typography variant="h6" sx={{ mb: 4, fontWeight: 'bold' }}>Skill Assignments</Typography>
                            }
                            {
                                skillAssignments?.map((assignment, index) => (
                                    <Button
                                        key={index}
                                        variant="contained"
                                        sx={{
                                            mb: 1,
                                            width: '100%',
                                            justifyContent: 'flex-start',
                                            padding: '12px 16px',
                                            fontSize: '16px'
                                        }}
                                        onClick={() => setSelectedChat(assignment)}
                                    >
                                        {assignment?.assignmentName}
                                    </Button>
                                ))
                            }
                        </>
                    )}
                    {
                        !skillAssignments?.length && !showModal && !openTodoModal &&
                        <NoAssignments />
                    }

                    {
                        isMobile &&
                        <>
                            {showModal && (
                                isStatic ? <StaticNotes /> : <Notes />
                            )}
                            {
                                openTodoModal &&
                                <>
                                    <Button variant="contained" startIcon={<ArrowBackRoundedIcon />} onClick={() => setOpenTodoModal(false)}>
                                        Back
                                    </Button>
                                    {isStatic ? <StaticTodoList page="todoList" /> : <TodoList page="todoList" />}
                                </>
                            }

                            <Box className={classes.fixedBottomButtonsSmallScreen} >
                                <Box className={classes.flexColCenter}>
                                    <ChatBot
                                        data={{ name: "Ameer" }}
                                        pageName={"Skill Section"}
                                    />
                                    <Typography variant="caption" sx={{ color: theme => theme.palette.common.text }}>Chat</Typography>
                                </Box>

                                <Box className={classes.flexColCenter}>
                                    <IconButton variant="rounded"
                                        onClick={openModal}
                                        sx={{ background: showModal && "red" }}
                                    >
                                        {showModal ? (
                                            <CloseIcon />
                                        ) : (
                                            <NoteAltRoundedIcon />
                                        )}
                                    </IconButton>
                                    <Typography variant="caption" sx={{ color: theme => theme.palette.common.text }}>Journal</Typography>
                                </Box>

                                {!isStatic &&
                                    <Box className={classes.flexColCenter}>
                                        <GoalsModal page={"Skill Section"} />
                                        <Typography variant="caption" sx={{ color: theme => theme.palette.common.text }}>Goals</Typography>
                                    </Box>
                                }

                                <Box className={classes.flexColCenter}>
                                    <IconButton variant="rounded" onClick={() => {
                                        setOpenTodoModal(true);
                                        setShowModal(false)
                                    }}>
                                        <FormatListBulletedIcon />
                                    </IconButton>
                                    <Typography variant="caption" sx={{ color: theme => theme.palette.common.text }}>To Do</Typography>
                                </Box>
                            </Box>
                        </>
                    }

                </Box>
            </StyledDrawer >
        </>
    )
}

export default SkillAssignment