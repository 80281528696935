import React, { useState, useEffect, useRef, useCallback } from 'react';
import Webcam from "react-webcam";
import { Button, Box, Typography } from '@mui/material';
import { v4 as uuidv4 } from "uuid";
import { uploadToStickS3 } from './S3Services';
import "./index.css"

export default function RecordVideo({ onSubmit }) {
    const webcamRef = useRef(null);
    const videoRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [timer, setTimer] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [permissionsGranted, setPermissionsGranted] = useState(false);
    const [play, setPlay] = useState(false);

    const handleStartCaptureClick = useCallback(() => {
        askPermission();
        setCapturing(true);
        setTimer(0);
        setIsRunning(true);

        // Check for supported MIME types
        const mimeType = MediaRecorder.isTypeSupported("video/webm;codecs=vp8,opus")
            ? "video/webm;codecs=vp8,opus"
            : "video/mp4"; // Fallback for iOS

        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType, // Use the supported MIME type
        });

        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();
    }, [webcamRef, setCapturing, mediaRecorderRef]);

    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                const blob = new Blob([data], { type: "video/webm" });
                setRecordedChunks((prev) => prev.concat(blob));
            }
        },
        [setRecordedChunks]
    );

    const handleStopCaptureClick = useCallback(() => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
            mediaRecorderRef.current.stop();
        }
        setCapturing(false);
        setIsRunning(false);
    }, [mediaRecorderRef, webcamRef, setCapturing]);

    const handleStateUpdate = async () => {
        if (recordedChunks.length > 0) {
            const blob = new Blob(recordedChunks, {
                type: "video/webm",
            });
            setTimer(0);
            setRecordedChunks([]);
            setIsRunning(false);
            try {
                ;
                const videoName = `video-${uuidv4()}.webm`;
                const response = await uploadToStickS3(new File([blob], videoName, { type: "video/webm" }));
                onSubmit(process.env.REACT_APP_S3_BUCKET_LINK + "the-stick/" + videoName)
            } catch (error) {
                console.error("Error uploading video:", error);
            }
        }
    };

    useEffect(() => {
        if (timer >= 30000) {
            handleStopCaptureClick();
        }
    }, [timer, handleStopCaptureClick]);

    useEffect(() => {
        let intervalId;

        if (isRunning) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1000); // Increase the timer by 1 second
            }, 1000);
        }

        return () => {
            clearInterval(intervalId); // Clean up the interval on component unmount
        };
    }, [isRunning]);

    const videoConstraints = {
        width: "380",
        height: "350",
        facingMode: "user",
    };

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600000);
        const minutes = Math.floor((time % 3600000) / 60000);
        const seconds = Math.floor((time % 60000) / 1000);
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(seconds).padStart(2, "0");
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    const askPermission = () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                .then(function (stream) {
                    setPermissionsGranted(true);
                })
                .catch(function (error) {
                    console.error('getUserMedia error:', error);
                    // Permission denied for camera or microphone
                    setPermissionsGranted(false);
                });
        } else {
            console.error('getUserMedia not supported on your browser');
        }
    };

    const checkPermissions = () => {
        navigator.permissions.query({ name: 'camera' }).then(function (cameraPermission) {
            navigator.permissions.query({ name: 'microphone' }).then(function (microphonePermission) {
                if (cameraPermission.state === 'granted' && microphonePermission.state === 'granted') {
                    setPermissionsGranted(true);
                } else {
                    setPermissionsGranted(false);
                }
            });
        });
    };

    useEffect(() => {
        // Check permissions when component mounts
        askPermission();
        checkPermissions();
    }, []);

    useEffect(() => {
        setRecordedChunks([]);
        setTimer(0);
    }, []);

    useEffect(() => {
        if (play && videoRef.current) {
            const videoBlob = new Blob(recordedChunks, { type: "video/webm" });
            const videoURL = URL.createObjectURL(videoBlob);

            videoRef.current.src = videoURL;

            videoRef.current.play().catch((error) => {
                if (error.name === "NotSupportedError") {
                    // Attempt to play the video with a different MIME type for Safari
                    const alternativeBlob = new Blob(recordedChunks, { type: "video/mp4" });
                    const alternativeURL = URL.createObjectURL(alternativeBlob);
                    videoRef.current.src = alternativeURL;
                    videoRef.current.play().catch((altError) => {
                        console.error("Alternative video playback error:", altError);
                    });
                } else {
                    console.error("Error playing video:", error);
                }
            });

            // Revoke the object URL after the video is loaded
            videoRef.current.onloadeddata = () => {
                console.log("Video loaded successfully");
                URL.revokeObjectURL(videoURL);
            };

            videoRef.current.onerror = (e) => {
                console.error("Video playback error:", e);
            };
        } else if (!play && videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
    }, [play, recordedChunks]);

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column" }}>
                {permissionsGranted ?
                    <>
                        <div className="web-cam">
                            {!recordedChunks.length && (
                                <Webcam
                                    className='recorded-video-style'
                                    style={{ borderRadius: '15px', transition: 'opacity 0.5s' }}
                                    ref={webcamRef}
                                    audio={true}
                                    muted
                                    mirrored={false}
                                    videoConstraints={videoConstraints}
                                />
                            )}
                            {!!recordedChunks.length && (
                                <video
                                    controls
                                    className='recorded-video-style'
                                    ref={videoRef}
                                    src={
                                        recordedChunks.length > 0
                                            ? URL.createObjectURL(
                                                new Blob(recordedChunks, { type: "video/webm" })
                                            )
                                            : null
                                    }
                                    style={{
                                        borderRadius: '15px',
                                        marginTop: '20px',
                                        opacity: recordedChunks.length > 0 ? 1 : 0,
                                        transition: 'opacity 0.5s'
                                    }}
                                    onError={() => {
                                        // Attempt to play the video with a different MIME type for Safari
                                        const alternativeBlob = new Blob(recordedChunks, { type: "video/mp4" });
                                        const alternativeURL = URL.createObjectURL(alternativeBlob);
                                        videoRef.current.src = alternativeURL;
                                        videoRef.current.play().catch((altError) => {
                                            console.error("Alternative video playback error:", altError);
                                        });
                                    }}
                                />
                            )}
                            {!recordedChunks.length && <div
                                className="cam-stream-time"
                                style={{ backgroundColor: capturing ? "#ff2525" : "#ccc" }}
                            >
                                {formatTime(timer)}
                            </div>}
                            {!recordedChunks.length && <div className="cam-buttons">
                                {capturing ? (
                                    <>
                                        <button
                                            disabled={timer < 4000}
                                            style={{ cursor: "pointer", opacity: timer < 4000 ? "0.5" : 1 }}
                                            className="cam-stop"
                                            onClick={handleStopCaptureClick}
                                        >
                                            <div className="stop-icon"></div>
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        style={{ cursor: "pointer" }}
                                        className="cam-start"
                                        onClick={handleStartCaptureClick}
                                    ></button>
                                )}
                            </div>}
                        </div>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: { xs: '10px', md: '20px' }, mt: { xs: 1, md: 2 } }}>
                            <Typography sx={{ opacity: 0.7, fontSize: { xs: '12px', md: '16px' } }}>
                                Record a video max 30 seconds
                            </Typography>
                            <Box sx={{ display: 'flex', gap: { xs: '10px', md: '20px' }, justifyContent: 'center' }}>
                                <Button
                                    disabled={(!recordedChunks.length && capturing) || timer === 0}
                                    sx={{ borderRadius: '10px', textTransform: 'capitalize', fontSize: { xs: '10px', md: '14px' }, minWidth: '80px', padding: '8px 16px' }}
                                    variant='outlined'
                                    onClick={() => {
                                        setTimer(0);
                                        setRecordedChunks([]);
                                    }}
                                >
                                    Retake
                                </Button>
                                <Button
                                    disabled={(!recordedChunks.length && capturing) || timer === 0}
                                    sx={{
                                        borderRadius: '10px',
                                        textTransform: 'capitalize',
                                        backgroundColor: '#106EAC',
                                        fontSize: { xs: '10px', md: '14px' },
                                        minWidth: { xs: "100px", md: "120px" },
                                        padding: '8px 16px',
                                    }}
                                    variant='contained'
                                    onClick={handleStateUpdate}
                                >
                                    {"Submit Video"}
                                </Button>
                            </Box>
                        </Box>
                    </>
                    :
                    <>
                        <Typography variant="body1" color="initial" sx={{ mx: 3 }}>
                            Grant camera and microphone permissions!
                        </Typography>
                        <Button
                            sx={{ borderRadius: '10px', textTransform: 'capitalize', fontWeight: "bold", m: 2 }}
                            variant='contained' onClick={askPermission}>
                            Grant Permissions
                        </Button>
                    </>
                }
            </div>
        </>
    );
}
