import { useState } from "react";
import {
  ADMIN_LINK,
  checkQuilValidation,
  DATA_TYPE,
  isAdminAdd,
  isAdminEdit,
  renderPlaceholder,
} from "../../../common/contentType/contentTypeData";
import { useFormik } from "formik";
import { Box, Button, Card, Grid, useTheme } from "@mui/material";
import { Adminwrapper, TypographyWithMore } from "../../../common";
import { useStyles } from "../../../../utils/styles";

import * as Yup from "yup";
import CustomCardWrapper from "../../../common/contentType/CustomCardWrapper";

const RenderImageVertical = ({ content }) => {
  const { QUILL, MEDIA } = DATA_TYPE;
  const { type, name, description } = content?.allContent;
  const theme = useTheme();
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: content?.allContent.content,
  });
  const classes = useStyles();
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      // name: Yup.string().required("Name is Required").matches(/(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)|<br>/g, "Name is Required matches"),
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Image is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });

  const handleImageVisibility = () => {
    if (!data?.content) return "../assets/img/dummy/img.jpeg";
    if (!data?.content.name) return data?.content;
    return URL.createObjectURL(data?.content);
  };
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <CustomCardWrapper>
      {isAdminAdd() && (
        <Button
          className={classes.btnAddStyle}
          onClick={contentFormik.handleSubmit}
        >
          ADD
        </Button>
      )}
      {isAdminEdit() && (
        <Button
          className={classes.btnUpdateStyle}
          onClick={contentFormik.handleSubmit}
        >
          Update
        </Button>
      )}

      <Grid container height="100%" spacing={{ xs: 1, md: 4 }} width="100%">
        <Grid item xs={12} md={6} display="flex" flexDirection="column" justifyContent='center'>
          <Adminwrapper
            errorText={contentFormik.errors.name}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="name"
          >
            {data?.name && (
              <Card
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: ".8rem",
                  boxShadow: "none",
                  width: "100%",
                  p: ".5rem",
                  mb: "1rem",
                }}
              >
                {renderPlaceholder(data?.name, "Enter Name")}
                <TypographyWithMore
                  variant="h2"
                  color={"white"}
                  data={data?.name}
                  textLength={10}
                  lines={2}
                  width="100%"
                />
              </Card>
            )}
          </Adminwrapper>

          <Adminwrapper
            errorText={contentFormik.errors.description}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="description"
          >
            {renderPlaceholder(data?.description, "Enter Description")}
            <TypographyWithMore
              variant="subTitle1"
              color="text"
              data={data?.description}
              textLength={120}
              lines={15}
            />
          </Adminwrapper>
        </Grid>
        <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems='center'>
          <Adminwrapper
            errorText={contentFormik.errors.content}
            dataType={MEDIA}
            data={data}
            setData={setData}
            type="content"
            mediaType={"IMAGE"}
          >
            <Box mt={{ xs: "1rem", md: "" }} sx={{ width: '100%', height: "63vh", aspectRatio: '2/3' }}>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: 'contain'
                }}
                src={handleImageVisibility()}
                alt={handleImageVisibility()}
              />
            </Box>
          </Adminwrapper>
        </Grid>
      </Grid>
    </CustomCardWrapper>
  );
};

export default RenderImageVertical;
