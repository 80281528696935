import React, { useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import './style.css';
import { EffectCards } from 'swiper/modules';
import SkillSection from '../../SkillSection';
import { useSelector } from 'react-redux';

export default function MobileCards({ data, handleNextClick, handlePrevClick }) {
    const swiperRef = useRef(null);
    const isNextEnabled = useSelector(state => state.user?.isNextEnabled)

    const toggleIframePointerEvents = (enable) => {
        const iframes = document.querySelectorAll('.swiper-slide iframe');
        iframes.forEach(iframe => {
            iframe.style.pointerEvents = enable ? 'auto' : 'none';
        });
    };

    const pauseAllMediaExceptCurrent = (currentIndex) => {
        const slides = swiperRef.current.swiper.slides;
        slides.forEach((slide, index) => {
            if (index !== currentIndex) {
                const mediaElements = slide.querySelectorAll('video, audio');
                mediaElements.forEach(media => {
                    console.log("Media Uability: ", media);
                    media.pause();
                    media.autoplay = false;
                    media.currentTime = 0; // Reset to start
                    media.muted = true; // Mute the media
                    // if (media.tagName === 'VIDEO' || media.tagName === 'AUDIO') {
                    //     media.src = ''; // Stop the media by resetting the source
                    // }
                });
            } else {
                const mediaElements = slide.querySelectorAll('video, audio');
                mediaElements.forEach(media => {
                    media.muted = false; // Unmute the media
                });
            }
        });
    };

    const handleSlideChange = (swiper) => {
        if (isNextEnabled) {
            return;
        }

        const currentIndex = swiper.activeIndex;
        const previousIndex = swiper.previousIndex;
        pauseAllMediaExceptCurrent(currentIndex);

        if (currentIndex > previousIndex) {
            handleNextClick();
        } else if (currentIndex <= previousIndex) {
            handlePrevClick();
        }
    };

    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.swiper.update();
            pauseAllMediaExceptCurrent(swiperRef.current.swiper.activeIndex);
        }

        const swiper = document.querySelector('.mySwiper');
        
        // Touch events
        swiper.addEventListener('touchstart', () => toggleIframePointerEvents(false));
        swiper.addEventListener('touchend', () => toggleIframePointerEvents(true));
        
        // Mouse events
        swiper.addEventListener('mousedown', () => toggleIframePointerEvents(false));
        swiper.addEventListener('mouseup', () => toggleIframePointerEvents(true));

        return () => {
            swiper?.removeEventListener('touchstart', () => toggleIframePointerEvents(false));
            swiper?.removeEventListener('touchend', () => toggleIframePointerEvents(true));
            swiper?.removeEventListener('mousedown', () => toggleIframePointerEvents(false));
            swiper?.removeEventListener('mouseup', () => toggleIframePointerEvents(true));
        };
    }, [data]);

    return (
        <>
            <Swiper
                ref={swiperRef}
                effect={'cards'}
                grabCursor={!isNextEnabled}
                modules={[EffectCards]}
                className="mySwiper"
                allowSlideNext={!isNextEnabled}
                allowSlidePrev={!isNextEnabled}
                onSlideChange={handleSlideChange}
                onSliderFirstMove={(swiper) => {
                    if (isNextEnabled) return
                    const currentIndex = swiper.activeIndex;
                    const touchedDirection = swiper?.touchesDirection
                    if (currentIndex == 0 && touchedDirection == "prev") {
                        handlePrevClick()
                    }
                }}
            >
                {
                    data?.map(content => {
                        return (
                            <SwiperSlide key={content.id}>
                                <SkillSection content={content} />
                            </SwiperSlide>
                        )
                    })
                }
                <SwiperSlide></SwiperSlide>
            </Swiper >
        </>
    );
}
