import React from 'react'

export default function CrownIcon({ color }) {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5246 7.79679C11.4913 8.33839 11.3796 8.77375 11.192 9.09199C11.032 9.36271 10.7998 9.53663 10.52 9.59471C10.2734 9.64591 10.008 9.60207 9.77196 9.47151C9.53596 9.34095 9.35708 9.13743 9.26892 8.89983C9.16924 8.63023 9.1934 8.33983 9.33884 8.05999C9.42996 7.88775 9.54889 7.73174 9.69084 7.59823L8.14108 5.61407L6.591 7.59807C6.73294 7.73158 6.85187 7.8876 6.943 8.05983C7.08844 8.33967 7.1126 8.63007 7.01292 8.89967C6.92508 9.13727 6.74636 9.34015 6.50988 9.47135C6.2734 9.60255 6.0086 9.64575 5.76188 9.59455C5.48204 9.53647 5.24988 9.36255 5.08988 9.09183C4.9022 8.77359 4.79052 8.33823 4.75724 7.79663L2.88892 7.45183L4.96156 11.0867H11.3206L13.3932 7.45183L11.5246 7.79679ZM8.23484 9.59215C8.22253 9.60448 8.20792 9.61426 8.19183 9.62093C8.17574 9.6276 8.15849 9.63104 8.14108 9.63104C8.12366 9.63104 8.10641 9.6276 8.09032 9.62093C8.07424 9.61426 8.05962 9.60448 8.04732 9.59215C8.02972 9.57455 7.61532 9.15423 7.61532 8.62079C7.61532 8.08735 8.02908 7.66719 8.04732 7.64943C8.07221 7.62462 8.10593 7.61068 8.14108 7.61068C8.17623 7.61068 8.20994 7.62462 8.23484 7.64943C8.25244 7.66719 8.66684 8.08735 8.66684 8.62079C8.66684 9.15423 8.25308 9.57455 8.23484 9.59215Z" fill={color} />
            <path d="M5.01709 11.3517H11.2651V12.3339H5.01709V11.3517Z" fill={color} />
            <path d="M8.14106 5.26643C8.49841 5.26643 8.7881 4.97674 8.7881 4.61939C8.7881 4.26204 8.49841 3.97235 8.14106 3.97235C7.78371 3.97235 7.49402 4.26204 7.49402 4.61939C7.49402 4.97674 7.78371 5.26643 8.14106 5.26643Z" fill={color} />
            <path d="M15.0762 7.27604C15.0762 7.44765 15.008 7.61222 14.8867 7.73357C14.7653 7.85491 14.6008 7.92308 14.4291 7.92308C14.2575 7.92308 14.093 7.85491 13.9716 7.73357C13.8503 7.61222 13.7821 7.44765 13.7821 7.27604V7.26964C13.783 7.09804 13.8519 6.9338 13.9739 6.81305C14.0958 6.69231 14.2607 6.62495 14.4323 6.6258C14.604 6.62665 14.7682 6.69563 14.8889 6.81758C15.0097 6.93952 15.077 7.10444 15.0762 7.27604Z" fill={color} />
            <path d="M2.5002 7.26961V7.27601C2.49935 7.44648 2.43092 7.60967 2.30993 7.72977C2.18893 7.84986 2.02524 7.91707 1.85476 7.91665C1.68429 7.91622 1.52093 7.8482 1.40054 7.7275C1.28014 7.60681 1.21252 7.44329 1.21252 7.27281C1.21252 7.10233 1.28014 6.93881 1.40054 6.81811C1.52093 6.69741 1.68429 6.62939 1.85476 6.62897C2.02524 6.62855 2.18893 6.69575 2.30993 6.81585C2.43092 6.93595 2.49935 7.09913 2.5002 7.26961Z" fill={color} />
            <path d="M8.4013 8.62079C8.4013 8.90207 8.24578 9.15503 8.14082 9.29279C8.03602 9.15471 7.88098 8.90271 7.88098 8.62079C7.88098 8.33887 8.0365 8.08671 8.14146 7.94879C8.24626 8.08623 8.4013 8.33807 8.4013 8.62079Z" fill={color} />
        </svg>

    )
}
