import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, useTheme, useMediaQuery } from '@mui/material';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CloseIcon from '@mui/icons-material/Close';
const DictionaryDialog = () => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton
                variant="rounded"
                onClick={handleOpen}
                color="primary"
                aria-label="open dictionary"
            >
                <AutoStoriesIcon />
            </IconButton>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: "15px",
                        m: { sm: "32px", xs: "3px" },
                        width: { sm: "calc(100% - 64px)", xs: "100%" },
                    }
                }
                }
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">Dictionary</Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <iframe
                        src={process.env.REACT_APP_BUDG_LINK + "/Dictionary?isIframe=true"}
                        title="Dictionary"
                        style={{
                            width: '100%',
                            height: isMobile ? '50vh' : '70vh',
                            border: 'none',
                        }}
                    />
                </DialogContent>
            </Dialog >
        </>
    );
};

export default DictionaryDialog; 