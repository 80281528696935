import { Button, Box, Card, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React from 'react'
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function DesktopQuestions({
  questions,
  questionsIndex,
  value,
  handleRadioChange,
  analyticsData,
  lang,
  theme,
  isMobile,
  handleResponse,
  decrementIndex,
  incrementIndex,
  checkFileName,
  inputField,
  descStyle,
}) {
  return (
    <Card
      sx={{
        backgroundColor: theme.palette.common.dialougeBg,
        borderRadius: "1.8rem",
        border: { sm: `1px solid ${theme.palette.common.disabled}`, xs: "none" },
        boxShadow: "none",
        width: "`100%",
        height: { md: "calc(100vh - 150px)", xs: "calc(100vh - 200px)" },
        p: "1.5rem",
        position: "relative",
      }}
    >
      <Card
        sx={{
          backgroundColor: theme.palette.primary.main,
          borderRadius: ".8rem",
          boxShadow: "none",
          width: "`100%",
          p: ".5rem",
        }}
      >
        <Typography
          variant="h5"
          color={theme.palette.common.white}
          textAlign="center"
        >
          {questions[questionsIndex]?.section}
        </Typography>
      </Card>
      <Card
        sx={{
          backgroundColor: theme.palette.common.white,
          borderRadius: "1.3rem",
          boxShadow: "none",
          height: isMobile ? "95vh" : "50vh",
          overflow: "auto",
          width: "100%",
          p: "1rem",
          mt: "1rem",
        }}
      >
        {questions && (
          <Box key={questionsIndex}>
            {questions[questionsIndex]?.isFreeText ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 3,
                  justifyContent: "center",
                }}
              >
                <Box display="flex" gap={1}>
                  <Typography
                    fontSize={{ xs: "12px", md: "18px" }}
                    color="text"
                  >
                    {questionsIndex + 1}.
                  </Typography>
                  <Typography
                    fontSize={{ xs: "12px", md: "18px" }}
                    fontWeight="700"
                    color="text"
                  >
                    {questions[questionsIndex]?.questionText}
                  </Typography>
                </Box>

                <TextField
                  label="Write Your Answer (at least 10 words)"
                  required
                  error={analyticsData[questionsIndex]?.answer?.split(" ")?.length < 10}
                  variant="outlined"
                  sx={inputField}
                  multiline
                  fullWidth
                  rows={8}
                  FormHelperTextProps={{ style: { textAlign: 'right', marginRight: 10 } }}
                  helperText={`${analyticsData[questionsIndex]?.answer?.split(" ")?.length || 0}/10`}
                  placeholder={
                    lang === "sp"
                      ? "Escribe tu respuesta"
                      : "Write Your Answer"
                  }
                  onChange={(e) => {
                    handleRadioChange(
                      e.target.value,
                      questionsIndex,
                      true
                    );
                  }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: 1,
                }}
              >
                <Box
                  width={{
                    xs: "100%",
                    md: questions[questionsIndex]?.questionImage
                      ? "50%"
                      : "100%",
                  }}
                >
                  <Box display="flex" gap={1}>
                    <Typography
                      fontSize={{ xs: "12px", md: "18px" }}
                      color="text"
                    >
                      {questionsIndex + 1}.
                    </Typography>
                    <Typography
                      fontSize={{ xs: "12px", md: "18px" }}
                      fontWeight="700"
                      color="text"
                    >
                      {questions[questionsIndex]?.questionText}
                    </Typography>
                  </Box>

                  <RadioGroup
                    aria-label={questionsIndex}
                    id={"question " + (questionsIndex + 1)}
                    name={"question " + (questionsIndex + 1)}
                    value={value[questionsIndex]?.opt}
                    onChange={(e) => {
                      handleRadioChange(
                        e.target.value,
                        questionsIndex,
                        false
                      );
                    }}
                    sx={{ ...descStyle, padding: "6px" }}
                  >
                    <Box>
                      {questions[questionsIndex]?.options?.map(
                        (op, j) => (
                          <Box key={j}>
                            <FormControlLabel
                              sx={{
                                color: "text",
                                "& span": {
                                  fontSize: {
                                    md: "14px",
                                    xs: "10px",
                                  },
                                  // p: {
                                  //   xs: "2px",
                                  //   md: "6px",
                                  // },
                                },
                              }}
                              value={op.id}
                              control={
                                <Radio
                                  // defaultChecked={
                                  //   value[j]?.opt == op.id
                                  // }
                                  sx={{
                                    color:
                                      theme.palette.primary.light,
                                    "&.Mui-checked": {
                                      color:
                                        theme.palette.primary.main,
                                    },
                                    "& .MuiSvgIcon-root": {
                                      fontSize: {
                                        xs: "15px",
                                        md: "26px",
                                      },
                                    },
                                  }}
                                />
                              }
                              label={op.optionText}
                            />
                          </Box>
                        )
                      )}
                    </Box>
                  </RadioGroup>
                </Box>
                {questions[questionsIndex]?.questionImage && (
                  <Box width={{ xs: "100%", md: "50%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {checkFileName == "mp4" ||
                        checkFileName === "m4v" ? (
                        <Box
                          sx={{
                            width: "100%",
                            maxHeight: isMobile ? "70vh" : "40vh",
                            aspectRatio: "1/1",
                          }}
                        >
                          <video
                            className="google-video"
                            controls
                            style={{
                              width: "90%",
                              height: "100%",
                              objectFit: "contain",
                              borderRadius: "1rem",
                            }}
                          >
                            <source
                              src={
                                questions?.[questionsIndex]
                                  ?.questionImage
                              }
                              type="video/mp4"
                            ></source>
                          </video>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            width: "100%",
                            maxHeight: isMobile ? "70vh" : "40vh",
                            aspectRatio: "1/1",
                          }}
                        >
                          <img
                            style={{
                              width: "90%",
                              height: "100%",
                              objectFit: "contain",
                              borderRadius: "1rem",
                            }}
                            src={
                              questions?.[questionsIndex]
                                ?.questionImage
                            }
                            alt=""
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
      </Card>

      <Box
        display="flex"
        gap="4rem"
        justifyContent={{ xs: "space-between", md: "space-around" }}
        alignItems="center"
        mt="1.5rem"
        position="absolute"
        bottom={20}
        width={{ xs: "90%", md: "95%" }}
        px={{ xs: "0rem", md: "5rem" }}
      >
        <Button
          variant="text"
          disabled={questionsIndex === 0}
          onClick={() => decrementIndex(questionsIndex)}
          startIcon={<ChevronLeftIcon sx={{ fontSize: "2rem" }} />}
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          }}
        >
          {lang === "sp" ? "Próximo" : "Previous"}
        </Button>

        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            width: "50%",
            gap: 0.5,
          }}
        >
          <LinearProgress
            variant="determinate"
            value={((questionsIndex + 1) * 100) / questions?.length}
            sx={{
              height: 7,
              borderRadius: 5,
              backgroundColor: theme.palette.common.white,
              border: { sm: `1px solid ${theme.palette.common.disabled}`, xs: "none" },
              "& .MuiLinearProgress-bar": {
                backgroundColor: theme.palette.success.main,
              },
            }}
          />
          <Typography
            color="text"
            textAlign="center"
            variant="body1"
          >
            {`${questionsIndex + 1}/${questions?.length}`}
          </Typography>
        </Box>

        {questionsIndex === questions?.length - 1 ? (
          <Button
            variant="contained"
            color="primary"
            disabled={(value.length !== questions.length) || (questions[questionsIndex]?.isFreeText && analyticsData[questionsIndex]?.answer?.split(" ")?.length < 10)}
            onClick={handleResponse}
          >
            {lang === "sp" ? "Entregar" : "Submit"}
          </Button>
        ) : (
          <Button
            variant="contained"
            disabled={(value.length == questionsIndex) || (questions[questionsIndex]?.isFreeText && analyticsData[questionsIndex]?.answer?.split(" ")?.length < 10)}
            onClick={() => incrementIndex(questionsIndex)}
            sx={{ px: "3rem" }}
          >
            {lang === "sp" ? "Próximo" : "Next"}
          </Button>
        )}
      </Box>
    </Card>
  )
}
