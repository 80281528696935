const ChatIconCustom = ({ color, width, height }) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    width={width || "40px"} // adjust size as needed
    height={height || "40px"} // adjust size as needed
  >
    <path
      d="M23.6667 7.99998V16C23.6667 18.0226 22.0227 19.6666 20 19.6666H8.90133C8.57867 19.6666 8.26533 19.7586 7.996 19.9346L5.57467 21.504C5.29867 21.684 4.984 21.7733 4.66667 21.7733C4.39333 21.7733 4.12 21.7053 3.86933 21.5706C3.33333 21.2786 3 20.7173 3 20.1066V7.99998C3 5.97731 4.644 4.33331 6.66667 4.33331H20C22.0227 4.33331 23.6667 5.97731 23.6667 7.99998ZM25.6667 9.67998V16C25.6667 19.12 23.12 21.6666 20 21.6666H9L8.41333 22.0533C8.74667 23.7333 10.2267 25 12 25H22.3067C23.1467 25 23.96 25.24 24.6667 25.6933L26.4267 26.84C26.7067 27.0133 27.0133 27.1066 27.3333 27.1066C27.6133 27.1066 27.88 27.04 28.1333 26.9066C28.6667 26.6133 29 26.0533 29 25.44V13.3333C29 11.4133 27.5333 9.85331 25.6667 9.67998Z"
      fill={color}
    />
  </svg>
);

export default ChatIconCustom;
