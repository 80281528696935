import { Card, useTheme } from "@mui/material";

const CustomCardWrapper = ({
  children,
  bgColor,
  height,
  mt,
  px,
  py,
  position,
  overflowX,
  overflow
}) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        backgroundColor: bgColor ? bgColor : "",
        mt: { sm: mt || "" },
        px: { xs: "1rem", md: px || "2rem" },
        py: { xs: "1rem", md: py || "2rem" },
        borderRadius: "1.8rem",
        border: { sm: `1px solid ${theme.palette.common.disabled}`, xs: "none" },
        boxShadow: "none",
        height: { xs: 'calc(100vh - 115px)', md: "calc(100vh - 150px)" },
        width: "100%",
        overflow: overflow ? overflow : "auto",
        position: position || "",
        overflowX: overflowX ? overflowX : "hidden"
      }}
    >
      {children}
    </Card>
  );
};

export default CustomCardWrapper;
