import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const InstallPrompt = () => {
    const [showInstallPrompt, setShowInstallPrompt] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isIOS, setIsIOS] = useState(false);
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        // Check if user has seen the prompt before
        const hasSeenPrompt = localStorage.getItem('hasSeenInstallPrompt');
        if (hasSeenPrompt) return;

        // Check if iOS
        const isIOSDevice = /iPhone/.test(navigator.userAgent) && !window.MSStream;
        setIsIOS(isIOSDevice);

        // Show iOS prompt if not installed
        if (isIOSDevice && !window.navigator.standalone && isMobileScreen) {
            setShowInstallPrompt(true);
        }

        // Handle PWA install prompt
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            if (isMobileScreen) {
                setDeferredPrompt(e);
                setShowInstallPrompt(true);
            }
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, [isMobileScreen]);

    const handleInstall = async () => {
        localStorage.setItem('hasSeenInstallPrompt', 'true');
        if (deferredPrompt) {
            deferredPrompt.prompt();
            const { outcome } = await deferredPrompt.userChoice;
            if (outcome === 'accepted') {
                setDeferredPrompt(null);
            }
        }
        handleClose();
    };

    const handleClose = () => {
        localStorage.setItem('hasSeenInstallPrompt', 'true');
        setShowInstallPrompt(false);
    };

    return (
        isMobileScreen ? (
            <Dialog
                open={showInstallPrompt}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        borderRadius: "32px",
                        padding: "1rem"
                    }
                }}
            >
                <DialogTitle>Install The App</DialogTitle>
                <DialogContent>
                    {isIOS ? (
                        <Box>
                            <Typography gutterBottom>
                                Install this application on your iPhone:
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                1. Tap the Share button
                                <br />
                                2. Scroll down and tap "Add to Home Screen"
                                <br />
                                3. Tap "Add" to install
                            </Typography>
                        </Box>
                    ) : (
                        <Typography>
                            Install this application on your device for quick and easy access when you're on the go.
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Not Now
                    </Button>
                    {!isIOS && (
                        <Button onClick={handleInstall} variant="contained" color="primary">
                            Install
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        ) : null
    );
};

export default InstallPrompt; 