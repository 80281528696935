import React, { useState, useEffect, memo } from "react";
import { Box, IconButton, useTheme, useMediaQuery, Divider, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Document, Page, pdfjs } from 'react-pdf';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
const PdfViewer = ({ pdfUrl }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }, []);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(isMobile ? 0.6 : 0.7);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      mx: 'auto',
      alignItems: 'center',
      position: 'relative',
      width: '100%',
      backgroundColor: "#fff"
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', bgcolor: "#414A4C", color: "#fff", p: 2, gap: 2 }}>
        <Typography variant="body1">
          <span style={{ backgroundColor: '#000', padding: '2px 6px', }}>{pageNumber}</span> / {numPages}
        </Typography>
        <Divider orientation="vertical" variant="middle" flexItem sx={{ height: "20px" }} />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: "#fff" }}>
          <IconButton disabled={scale <= 0.6} onClick={() => setScale(scale - 0.1)} color="inherit">
            <RemoveIcon />
          </IconButton>
          <Typography variant="body1" sx={{ color: "#fff", bgcolor: "#000", p: "2px" }}>
            {Math.round(scale * 100)}%
          </Typography>
          <IconButton disabled={scale >= 2} onClick={() => setScale(scale + 0.1)} color="inherit">
            <AddIcon />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{
        width: '100%',
        height: 'calc(100vh - 300px)', // Adjust this value based on your toolbar height
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
      }}>
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          loading="Loading PDF..."
          error="Failed to load PDF. Please try again."
        >
          <Page
            pageNumber={pageNumber}
            renderTextLayer={true}
            renderAnnotationLayer={true}
            scale={scale}
          />
        </Document>
      </Box>

      <IconButton
        disabled={pageNumber === 1}
        onClick={() => setPageNumber(pageNumber - 1)}
        sx={{ ...btnStyle, left: { md: '0px', xs: '-20px' } }}>
        <ArrowBackIcon sx={{ ...iconStyle }} />
      </IconButton>
      <IconButton
        onClick={() => setPageNumber(pageNumber + 1)}
        disabled={pageNumber === numPages}
        sx={{ ...btnStyle, right: { md: '0px', xs: '-20px' } }}>
        <ArrowForwardIcon sx={{ ...iconStyle }} />
      </IconButton>
    </Box>
  );
};

export default memo(PdfViewer);
const iconStyle = {
  width: { md: '40px', xs: '20px' },
  height: { md: '40px', xs: '20px' },
  color: '#fff'
}

const btnStyle = {
  backgroundColor: 'primary.main',
  zIndex: 1000,
  height: { md: '50px', xs: '30px' },
  width: { md: '50px', xs: '30px' },
  boxShadow: (theme) => `0px 0px 2px 0px ${theme.palette.primary.main}`,
  position: 'absolute',
  top: '50%',
  bottom: '50%',
  '&:hover': {
    boxShadow: (theme) => `0px 0px 5px 0px ${theme.palette.primary.main}`,
    backgroundColor: 'primary.main',
  },
  '&:disabled': {
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  }

}