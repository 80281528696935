import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CustomVideoPlayer, { TiktokVideoPlayer } from "./CustomVideoPlayer";

export const VideoDisplayModal = ({
  link,
  open,
  handleClose,
  responseType,
}) => {
  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
        sx={{
          "& .css-thk4mm-MuiDialogContent-root": {
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "10px",
              "&:hover": {
                background: "#555",
              },
            },
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          {responseType === "tiktok" &&
          link?.includes("cdn") &&
          (link?.includes("tiktokcdn") || link?.includes("tiktok")) ? (
            <TiktokVideoPlayer videoUrl={link} />
          ) : responseType === "youtube" &&
            !link?.includes("cdn") &&
            !link?.includes("tiktokcdn") &&
            !link?.includes("tiktok") ? (
            <CustomVideoPlayer linkId={link} />
          ) : null}
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
