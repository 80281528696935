import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

const LogoImage = ({biggerLogo = false}) => {
  const logo = useSelector(state => state.user.userData?.logo) || ""


  return (
    <Box
      component="img"
      sx={{
        // width: { xs: "120px", md: "240px" },
        height: 'fit-content',
        objectFit: 'contain',
        maxHeight: { xs: '50px', md: biggerLogo ? '90px' : '50px' },
        '@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)': {
          imageRendering: '-webkit-optimize-contrast',
          maxHeight: { xs: '50px', md: biggerLogo ? '90px' : '50px' },
        },
      }}
      src={logo}
      alt="Logo"
    />
  );
};

export default LogoImage;
