import React, { useState, useEffect, useRef } from 'react';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SettingsEthernetRoundedIcon from '@mui/icons-material/SettingsEthernetRounded';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import { useStyles } from '../../../utils/styles';

function ResizableBoxes({ children, height }) {
    const [leftBoxWidth, setLeftBoxWidth] = useState(50); // Percentage width for the left box
    const [topBoxHeight, setTopBoxHeight] = useState(50); // Percentage height for the top box
    const [isDragging, setIsDragging] = useState(false);
    const containerRef = useRef(null);
    const theme = useTheme();
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleMouseDown = () => {
        setIsDragging(true);
    };

    const handleMouseMove = (e) => {
        if (isDragging && containerRef.current) {
            const container = containerRef.current;
            const containerRect = container.getBoundingClientRect(); // Get container position and dimensions

            if (isMobile) {
                const newTopBoxHeight = ((e.touches[0].clientY - containerRect.top) / containerRect.height) * 100; // Calculate height as percentage
                if (newTopBoxHeight >= 10 && newTopBoxHeight <= 90) { // Limit box height to avoid collapsing
                    setTopBoxHeight(newTopBoxHeight);
                }
            } else {
                const newLeftBoxWidth = ((e.clientX - containerRect.left) / containerRect.width) * 100; // Calculate width as percentage
                if (newLeftBoxWidth >= 10 && newLeftBoxWidth <= 90) { // Limit box width to avoid collapsing
                    setLeftBoxWidth(newLeftBoxWidth);
                }
            }
        }
    };

    const handleTouchMove = (e) => {
        e.preventDefault(); // Prevent default touch behavior (scrolling)
        e.stopPropagation(); // Stop event from bubbling up
        handleMouseMove(e);
    };

    // Add touchstart prevention
    const handleTouchStart = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleMouseDown();
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        if (isDragging) {
            // Mouse events
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);

            // Touch events
            window.addEventListener('touchmove', handleTouchMove);
            window.addEventListener('touchend', handleMouseUp);
        } else {
            // Remove mouse events
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);

            // Remove touch events
            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('touchend', handleMouseUp);
        }

        return () => {
            // Cleanup mouse events
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);

            // Cleanup touch events
            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('touchend', handleMouseUp);
        };
    }, [isDragging]);

    return (
        <Box
            ref={containerRef}
            display="flex"
            sx={{
                height: height || { xs: "62vh", md: 'calc(100vh - 200px)' },
                mt: "20px",
                width: '100%',
                gap: { sm: '10px' },
                flexDirection: { sm: "row", xs: "column" },
                alignItems: { xs: "flex-end", sm: "center" },
                touchAction: 'none', // Add this to prevent default touch handling
            }} // Full width and height container
        >
            <ResizableBoxes.Left
                leftBoxWidth={leftBoxWidth}
                topBoxHeight={topBoxHeight}
                isDragging={isDragging}
                classes={classes}
            >
                {children[0]}
            </ResizableBoxes.Left>
            <IconButton variant="dragable"
                onMouseDown={handleMouseDown} // Start dragging on mouse down
                onTouchStart={handleTouchStart} // Updated to use new handler
                sx={{ cursor: 'move', zIndex: 1, }}
            >
                {isMobile ? <UnfoldMoreRoundedIcon /> : <SettingsEthernetRoundedIcon />}
            </IconButton>
            <ResizableBoxes.Right
                leftBoxWidth={leftBoxWidth}
                topBoxHeight={topBoxHeight}
                isDragging={isDragging}
                classes={classes}
            >
                {children[1]}
            </ResizableBoxes.Right>
        </Box>
    );
}

export default ResizableBoxes;

ResizableBoxes.Left = ({ children, leftBoxWidth, topBoxHeight, isDragging, classes }) => {
    return (
        <Box
            className={classes?.resizeableBox}
            sx={{
                width: { sm: `${leftBoxWidth}%`, xs: '100%' },
                height: { xs: `${topBoxHeight}%`, sm: '100%' },
                transition: isDragging ? 'none' : 'all 0.2s ease',
            }}
        >
            {children}
        </Box>
    );
}

ResizableBoxes.Right = ({ children, leftBoxWidth, topBoxHeight, isDragging, classes }) => {
    return (
        <Box
            className={classes?.resizeableBox}
            sx={{
                width: { sm: `${100 - leftBoxWidth}%`, xs: '100%' },
                height: { xs: `${100 - topBoxHeight}%`, sm: '100%' },
                transition: isDragging ? 'none' : 'all 0.2s ease',
            }}
        >
            {children}
        </Box>
    );
}
