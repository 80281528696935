import React, { useState, useEffect, useRef } from "react";
import { Button, Grid, Box, useMediaQuery, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Adminwrapper, TypographyWithMore } from "../../../common";
import {
  ADMIN_LINK,
  DATA_TYPE,
  isAdminAdd,
  isAdminEdit,
  renderPlaceholder,
  LazzyLoading,
} from "../../../common/contentType/contentTypeData";
import { useStyles } from "../../../../utils/styles";
import CustomCardWrapper from "../../../common/contentType/CustomCardWrapper";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

let UNITY_LINK = process.env.REACT_APP_UNITY_APP_LINK;

const Metaverse = ({ content }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isIphone, setIsIphone] = useState(false);
  const { type, name, description } = content?.allContent;
  const [data, setData] = useState({
    content: content?.allContent?.content,
    type,
    name,
    description,
  });
  const iframeRef = useRef(null);

  const { QUILL, METAVERSE } = DATA_TYPE;
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd()
        ? {
          ...data,
          points: content?.points,
          name: "Name",
          description: "Description",
        }
        : data,
      ADMIN_LINK
    );
  };

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    if (/iPhone/i.test(userAgent)) {
      setIsIphone(true);
    } else {
      setIsIphone(false);
    }
  }, [isMobile])

  const handleFullScreen = () => {
    try {
      const iframeElement = iframeRef.current;
      if (!iframeElement) return;

      if (!isFullScreen) {
        if (isIphone) {
          // Apply fullscreen styles directly to iframe
          iframeElement.style.position = 'fixed';
          iframeElement.style.top = '0';
          iframeElement.style.left = '0';
          iframeElement.style.width = '100%';
          iframeElement.style.height = '100%';
          iframeElement.style.zIndex = '9999';
          iframeElement.style.backgroundColor = '#000';
          iframeElement.style.border = 'none';
          iframeElement.style.borderRadius = '0';
        }
      } else {
        if (isIphone) {
          // Reset styles
          iframeElement.style.position = 'relative';
          iframeElement.style.top = 'auto';
          iframeElement.style.left = 'auto';
          iframeElement.style.width = '100%';
          iframeElement.style.height = '100%';
          iframeElement.style.zIndex = 'auto';
          iframeElement.style.backgroundColor = 'transparent';
          iframeElement.style.borderRadius = '1.8rem';
        }
      }
      setIsFullScreen(!isFullScreen);
    } catch (err) {
      console.log('Fullscreen style change failed:', err);
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data?.type === 'fullscreenChange') {
        setIsFullScreen(event.data.isFullscreen);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return (
    <CustomCardWrapper
      bgColor={theme.palette.common.disabled}
      height={"70vh"}
      // mt="60px"
      px="40px"
      py="40px"
      overflow="visible"
      overflowX="visible"
    >
      <Grid container sx={{ height: "100%", m: 0, gap: "29px" }}>
        <Grid sx={{ order: { xs: 2, sm: 1 } }} md={6.8} sm={5.8} xs={12}>
          <Adminwrapper
            setData={setData}
            type="content"
            dataType={METAVERSE}
            data={data}
          >
            <Box sx={{ position: "relative", height: "100%" }}>
              {/* {!isLoaded && <LazzyLoading />} */}

              <iframe
                ref={iframeRef}
                onLoad={() => setIsLoaded(true)}
                allowFullScreen={true}
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allow="fullscreen; xr-spatial-tracking; accelerometer; gyroscope; autoplay"
                src={getUnityLink(data.content)}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "1.8rem",
                  position: "relative",
                }}
              ></iframe>
              {
                isIphone &&
                <IconButton
                  sx={{ position: "fixed", right: 10, bottom: 10, zIndex: 99999, color: 'white', backgroundColor: "rgba(0,0,0,0.5)", width: "fit-content", height: "fit-content" }}
                  onClick={handleFullScreen}
                >
                  {
                    isFullScreen ?
                      <FullscreenExitIcon sx={{ width: 35, height: 35 }} />
                      :
                      <FullscreenIcon sx={{ width: 35, height: 35 }} />
                  }
                </IconButton>
              }
            </Box>
          </Adminwrapper>
        </Grid>
        <Grid
          sx={{
            order: { xs: 1, sm: 2 },
            bgcolor: theme.palette.common.white,
            borderRadius: "1.8rem",
            padding: { sm: "40px", xs: "10px" },
            height: { sm: "100%", xs: "fit-content" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          md={4.8}
          sm={5.8}
          xs={12}
        >
          <TypographyWithMore
            textAlign="left"
            textLength={8}
            lines={2}
            variant="h2"
            color="primary"
            data={data?.name}
          />
          <TypographyWithMore
            textAlign="left"
            textLength={50}
            lines={6}
            variant="body1"
            color="text"
            data={data?.description}
          />
        </Grid>
      </Grid>
    </CustomCardWrapper>
  );
};

export default Metaverse;


const getUnityLink = (value) => {
  switch (value) {
    case "mall":
      return UNITY_LINK + "mall";
    case "room":
      return UNITY_LINK + "showroom";
    case "advisor":
      return UNITY_LINK + "financial-advisor";
    case "doctor-office":
      return UNITY_LINK + "doctor-office";
    case "hospital":
      return UNITY_LINK + "hospital";
    case "stock":
      return UNITY_LINK + "grocery-stock";
    case "resources":
      return UNITY_LINK + "human-resources";
    case "grocery":
      return UNITY_LINK + "grocery-store";
    case "personal-banking":
      return UNITY_LINK + "personal-banking";
    case "banking-roles":
      return UNITY_LINK + "banking-roles";
    case "first-tech":
      return UNITY_LINK + "first-tech";
    case "stickball-bank":
      return UNITY_LINK + "stickball-bank";
    case "fidelity-investments":
      return UNITY_LINK + "fidelity-investments";
    case "stickball-company":
      return UNITY_LINK + "stickball-company";
    case "musketeers-mall":
      return UNITY_LINK + "musketeers-mall";
    case "smart-shoping-mall":
      return UNITY_LINK + "smart-shop-mall";
    case "stickball-apartments":
      return UNITY_LINK + "stickball-apartments";
    case "polygon-office":
      return UNITY_LINK + "polygon-office";
    case "stickball-school":
      return UNITY_LINK + "school";
    case "careers":
      return UNITY_LINK + "careers";
    case "stickball-business":
      return UNITY_LINK + "business";
    case "jobs":
      return UNITY_LINK + "jobs";
    case "investing":
      return UNITY_LINK + "investing";
    default:
      return value;
  }
};