import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import {
  ResizableBoxes,
  TypographyWithMore,
} from "../../../common";
import {
  getName,
  isValidUrl,
} from "../../../common/contentType/contentTypeData";
import StickbotTop from "../../../common/contentType/StickbotTop";
import CustomVideoPlayer from "../../../common/contentType/CustomVideoPlayer";

const VideoStickbot = ({ content, user }) => {
  const myObj = JSON.parse(content?.allContent?.content);
  const { wireframe_link, wireframe_link1, text } = myObj;
  const { name, description, type } = content?.allContent;
  const [data, setStateData] = useState({
    name,
    description,
    type,
    wireframe_link,
    wireframe_link1,
    text,
  });

  const handleVideoVisibility = () => {
    if (!data?.wireframe_link1) return "https://youtu.be/9xwazD5SyVg";
    if (!data?.wireframe_link1.name)
      return process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link1;
    return URL.createObjectURL(data?.wireframe_link1);
  };
  return (
    <Grid container>
      <Grid item xs={12}>

        <TypographyWithMore
          textAlign="left"
          textLength={8}
          lines={1}
          variant="h2"
          color="primary"
          data={data?.name}
        />
      </Grid>
      {!!data?.description?.replace(/<[^>]*>/g, "").trim() && (
        <Grid
          item
          xs={data?.description?.replace(/<[^>]*>/g, "").trim() ? 12 : 0}
        >
          <TypographyWithMore
            textAlign="left"
            textLength={20}
            lines={2}
            variant="body1"
            color="text"
            data={data?.description}
          />
        </Grid>
      )}
      <ResizableBoxes height="calc(100vh - 250px)">
        <Box display={"flex"} justifyContent={"center"}>
          <CustomVideoPlayer
            url={handleVideoVisibility()}
            text={data?.text || "No Transcript Added"}
            autoPlay={false}
          />
        </Box>

        <>
          <StickbotTop url={isValidUrl(data?.wireframe_link)
            ? data?.wireframe_link + `?userId=${getName(user)}`
            : "www.google.com"} />
        </>
      </ResizableBoxes>
    </Grid>
  );
};

export default VideoStickbot;
