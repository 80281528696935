import React, { useState, useRef } from 'react';
import { Box, Button, TextField, Typography, useMediaQuery, IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import toast from 'react-hot-toast';
import { QuillEditor } from '../quil-editor';
import { uploadToStickS3 } from '../qr-scaned/S3Services';
import { generateScript, postTheStickContent } from '../../../_api/the-stick';
import RecordVideo from '../qr-scaned/video-recorder';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CustomCardWrapper from '../../../common/contentType/CustomCardWrapper';
import { TypographyWithMore } from '../../../common';
import QRCode from "react-qr-code";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
export default function TheStick({ content }) {
    const moduleName = useSelector(state => state.user.moduleName)
    const lessonName = useSelector(state => state.user.lessonName)
    const user = useSelector(state => state.user.userData?.user)
    content = content?.allContent || {}
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const [openModal, setOpenModal] = useState(false)
    const [title, setTitle] = useState("")
    const [script, setScript] = useState("")
    const [type, setType] = useState(null)
    const [fileUrl, setFileUrl] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [step, setStep] = useState(0)
    const titleRef = useRef(null)
    const handleClose = () => {
        setOpenModal(false)
        setType(null)
        setOpenModal(false)
        setFileUrl("")
    }
    const handleOpen = () => setOpenModal(true)
    const handleUpload = async (e) => {
        let res = await uploadToStickS3(e.target.files[0])
        await handleSubmit(process.env.REACT_APP_S3_BUCKET_LINK + "the-stick/" + res)
        setFileUrl(res)
        setStep(0)
    }

    const createScript = async () => {
        setIsLoading(true)
        toast.loading("Creating Script, Please wait...")
        let script = await generateScript(title)
        setScript(script)
        setIsLoading(false)
        toast.dismiss()
        toast.success(`Script created successfully`)
    }

    const handleSubmit = async (url) => {
        setIsLoading(true)
        let userDetials = JSON.parse(localStorage.getItem("userDetails"))
        toast.loading("Submitting, Please wait...")
        let res = await postTheStickContent({
            video_url: url,
            category: content?.content || "Music",
            module_name: moduleName,
            lesson_name: lessonName,
            user_name: userDetials?.name + " " + userDetials?.lastName,
            organization_id: user?.organizationId
        })
        setIsLoading(false)
        setStep(0)
        toast.dismiss()
        toast.success(`Submitted successfully`)
        setFileUrl("")
    }


    return (
        <CustomCardWrapper>
            <Box sx={{ p: { sm: 3, xs: 1 }, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", width: "100%" }}>
                {
                    step == 0 ?
                        <>
                            <Box sx={{ px: { lg: 20, md: 0 }, display: "flex", flexDirection: "column", gap: 2 }}>
                                <TypographyWithMore
                                    variant="h2"
                                    color="primary"
                                    data={content?.name}
                                    textLength={10}
                                    lines={2}
                                    textAlign="center"
                                    width="100%"
                                />
                                <TypographyWithMore
                                    variant="body1"
                                    color="text"
                                    data={content?.description}
                                    textLength={50}
                                    lines={5}
                                    textAlign="center"
                                    width="100%"
                                />
                            </Box>
                            <Box sx={boxStyle}>
                                <Box sx={{ ...innerBoxStyle, backgroundColor: "#DC74744D" }} >
                                    <Box sx={{ borderRadius: "5px", p: 1, bgcolor: "error.main", width: 'fit-content' }}>
                                        <DescriptionOutlinedIcon color="light" />
                                    </Box>
                                    <Typography variant="h5" sx={{ opacity: 0.7 }} fontWeight={700}>
                                        Video Script
                                    </Typography>
                                    <Button
                                        onClick={() => {
                                            setStep(1)
                                        }}
                                        variant='contained'
                                        sx={{ textTransform: "capitalize", borderRadius: '10px', width: '100%', fontSize: "16px" }}>
                                        Generate Video Script
                                    </Button>
                                </Box>
                                <Box sx={{ ...innerBoxStyle, backgroundColor: "primary.light" }}>
                                    <Box sx={{ borderRadius: "5px", p: 1, bgcolor: "error.main", width: 'fit-content', color: "light" }}>
                                        <VideocamOutlinedIcon color="light" />
                                    </Box>
                                    <Typography variant="h5" sx={{ opacity: 0.7 }} fontWeight={700}>
                                        Record Myself
                                    </Typography>
                                    <Button
                                        onClick={() => {
                                            setStep(2)
                                        }}
                                        variant='contained'
                                        sx={{ textTransform: "capitalize", borderRadius: '10px', width: '100%', fontSize: "16px" }}>
                                        Record Or Get QR
                                    </Button>
                                </Box>
                                <Box sx={{ ...innerBoxStyle, backgroundColor: "lightgray" }} >
                                    <Box sx={{ borderRadius: "5px", p: 1, bgcolor: "error.main", width: 'fit-content', color: "light" }}>
                                        <FileUploadOutlinedIcon color="light" />
                                    </Box>
                                    <Typography variant="h5" sx={{ opacity: 0.7 }} fontWeight={700}>
                                        {fileUrl ? "Update Video" : "Upload Video"}
                                    </Typography>
                                    <Button
                                        onClick={() => {
                                            setStep(3)
                                        }}
                                        variant='contained'
                                        sx={{ textTransform: "capitalize", borderRadius: '10px', width: '100%', fontSize: "16px" }}>
                                        Upload

                                    </Button>

                                </Box>

                            </Box>
                        </>
                        : step == 1 ?
                            <>
                                <Box sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
                                    {
                                        !script &&
                                        <Button variant='contained' startIcon={<ArrowBackIcon />} onClick={() => setStep(0)}>
                                            Back
                                        </Button>
                                    }
                                </Box>
                                <Box sx={{ width: "100%", display: "flex", flexDirection: { md: "row", xs: "column" }, alignItems: "center", gap: 2 }}>
                                    <Box sx={{
                                        flex: 1,
                                        p: { md: 3, xs: 1 },
                                        bgcolor: "lightgray",
                                        borderRadius: "15px",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        boxShadow: 3
                                    }}>
                                        <Typography variant="h4" color="initial" sx={{ px: { md: 4, xs: 1 }, textAlign: "center" }}>
                                            Enter video title to generate a script
                                        </Typography>
                                        <TextField
                                            inputRef={titleRef}
                                            disabled={isLoading}
                                            multiline
                                            minRows={5}
                                            fullWidth
                                            label="Type here"
                                            placeholder="Type here..."
                                            onChange={(e) => setTitle(e.target.value)}
                                            value={title}
                                            sx={{ mt: 3 }}
                                        />
                                        <Button
                                            fullWidth
                                            disabled={isLoading}
                                            onClick={createScript}
                                            variant='contained'
                                            sx={{ textTransform: "capitalize", borderRadius: '10px', mt: 2, p: 1, fontSize: "16px" }}
                                        >Submit</Button>
                                    </Box>

                                    <Box sx={{
                                        flex: 1,
                                        p: { md: 2, xs: 1 },
                                        borderRadius: "15px",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        height: "315px",
                                        overflow: "auto",
                                        width: { sm: "300px", xs: "100%" },
                                        boxShadow: 3
                                    }}>
                                        <TextField
                                            disabled={isLoading}
                                            multiline
                                            rows={8}
                                            fullWidth
                                            onChange={(e) => setScript(e.target.value)}
                                            value={script}
                                            sx={{
                                                height: "calc(100% - 40px)",
                                                backgroundColor: "lightgray",
                                                width: "100%",
                                                borderRadius: "15px",
                                                overflowY: "auto",
                                                overflowX: "hidden",
                                                p: 2,
                                                textAlign: "left"

                                            }}
                                        />
                                        <Box sx={{ display: "flex", alignItems: "center", gap: 2, justifyContent: "space-between", width: "100%" }}>

                                            <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                                                <Button
                                                    onClick={() => {
                                                        setStep(2)
                                                    }}
                                                    variant='contained'
                                                    sx={{ textTransform: "capitalize", borderRadius: '10px' }}>
                                                    Record
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setStep(3)
                                                    }}
                                                    variant='contained'
                                                    sx={{ textTransform: "capitalize", borderRadius: '10px' }}>
                                                    Upload
                                                </Button>
                                            </Box>

                                            <Button
                                                onClick={() => {
                                                    titleRef.current.focus()
                                                }}
                                                variant='contained'
                                                sx={{ textTransform: "capitalize", borderRadius: '10px', mt: 2 }}
                                            >Regenerate Script</Button>
                                        </Box>
                                    </Box>

                                </Box>
                            </>
                            : step == 2 ?
                                <>
                                    <Box sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
                                        <Button variant='contained' startIcon={<ArrowBackIcon />} onClick={() => setStep(0)}>
                                            Back
                                        </Button>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, alignItems: "center", gap: 2, justifyContent: "space-between", width: "100%" }}>
                                        {
                                            !isMobile && (
                                                <Box sx={{ flex: 1 }}>
                                                    <Typography variant="h3" textAlign="left" color="initial">
                                                        To use your mobile camera to record a video:
                                                    </Typography>
                                                    <Typography textAlign="left" variant="body1" color="initial" marginTop={2}>
                                                        <ol>
                                                            <li>Open your mobile camera app.</li>
                                                            <li>Tap on the QR code scanning option.</li>
                                                            <li>Point your camera to this screen to capture the code.</li>
                                                            <li>Once scanned, your camera will automatically open in video recording mode.</li>
                                                        </ol>
                                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", mt: 2 }}>
                                                            <QRCode size={200} value={`${window.location.origin}/qr-scaned`} />
                                                        </Box>
                                                    </Typography>
                                                </Box>
                                            )
                                        }
                                        <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                                            <RecordVideo onSubmit={handleSubmit} />
                                        </Box>
                                    </Box>

                                </>
                                :
                                <>
                                    <Box sx={{ display: "flex", justifyContent: "flex-start", width: { md: "60%", xs: "95%" } }}>
                                        <Button sx={{ my: 1 }} variant='contained' startIcon={<ArrowBackIcon />} onClick={() => setStep(0)}>
                                            Back
                                        </Button>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>

                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            maxWidth: { xs: "95%", md: "60%" },
                                            position: "relative",
                                            boxShadow: 3,
                                            borderRadius: "15px",
                                            p: !fileUrl && 5,
                                            bgcolor: "primary.light"
                                        }}>
                                            {
                                                fileUrl ?
                                                    <video style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "15px" }} controls playing src={process.env.REACT_APP_S3_BUCKET_LINK + "the-stick/" + fileUrl}></video>
                                                    :
                                                    <>
                                                        <Box sx={{ borderRadius: "5px", p: 1, bgcolor: "primary.main", width: 'fit-content', color: "light" }}>
                                                            <FileUploadOutlinedIcon color="light" sx={{ fontSize: "60px" }} />
                                                        </Box>
                                                        <Typography variant="h4" color="initial" sx={{ mt: 3 }}>
                                                            Click or drag file to this area to upload
                                                        </Typography>
                                                        <Typography variant="body1" color="initial" marginTop={2}>
                                                            Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
                                                        </Typography>
                                                        <input accept='video/mp4' type="file" style={invisibleInput} onChange={handleUpload} />
                                                    </>
                                            }
                                        </Box>

                                    </Box>
                                </>
                }
            </Box>
        </CustomCardWrapper >
    )
}

const boxStyle = {
    mt: 5,
    width: "100%",
    display: 'flex',
    flexDirection: { md: "row", xs: "column" },
    gap: "30px",
    justifyContent: "center",
    alignItems: "center",
    color: "text.primary"
}
const innerBoxStyle = {
    minHeight: "150px",
    p: 2.5,
    width: { sm: "300px", xs: "100%" },
    textTransform: "capitalize",
    borderRadius: "12px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: 3
}
const invisibleInput = {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    opacity: 0,
    cursor: "pointer"
}
const quilStyle = {
    fontSize: "16px",
    marginBottom: "20px",
    marginTop: "20px",
};