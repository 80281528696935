import { useTheme } from "@mui/material/styles";
import { Slide, Typography } from "@mui/material";
import { forwardRef } from "react";

export const ADMIN_LINK = process.env.REACT_APP_ADMIN_APP
export let UNITY_LINK = process.env.REACT_APP_UNITY_APP_LINK

export const isAdminEdit = () => sessionStorage.getItem("view") === "admin-edit"; //admin-edit || admin
export const isAdminAdd = () => sessionStorage.getItem("view") === "admin-add"; //admin-add || admin
export const isAdminView = () => sessionStorage.getItem("view") === "admin"; //admin-view || admin


export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getName = (user) => {
  if (user?.firstName && user?.lastName) {
    return `${user?.firstName} ${user?.lastName}`;
  } else if (user?.firstName) {
    return user?.firstName;
  } else if (user?.lastName) {
    return user?.lastName;
  } else {
    return "_User";
  }
};
export const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export const LazzyLoading = () => {
  const theme = useTheme()
  return (
    <div>
      <div style={{ '--primary-color': theme.palette.primary.main }} className="lazzy-container d-flex flex-column">
        <Typography variant="h4" color="primary" sx={{ marginBottom: "20px" }}>Loading...</Typography>
        <Typography variant="h4" color="primary" sx={{ marginBottom: "20px" }}>Always Be Learning (ABL)</Typography>
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};


export function extractTextFromHTML(htmlContent) {
  // Create a new DOM element and set the HTML content to it
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent;

  // Get text content and handle line breaks, then decode HTML entities
  return tempDiv.innerHTML
    .replace(/<br\s*\/?>/gi, '\n')
    .replace(/<[^>]+>/g, '')
    .replace(/&amp;/g, '&'); // Convert &amp; back to &
}

export const checkQuilValidation = (value) => {
  if (!value) return true;
  const regex = /<[^>]+>(.*?)<\/[^>]+>/gi;
  const matches = value.match(regex);
  return matches.some((match) => match !== "<p><br></p>");
};

export const renderPlaceholder = (data, placeholderText) => {
  return (
    (isAdminAdd() || isAdminEdit()) &&
    !data && <p style={{ margin: '10px' }}>{placeholderText}</p>
  );
};

export const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      style={{
        justifyContent: "right",
      }}
      ref={ref}
      {...props}
    />
  );
});

export const CCTransition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      style={{
        justifyContent: "center",
      }}
      ref={ref}
      {...props}
    />
  );
});


export const DATA_TYPE = {
  QUILL: 'QUILL_EDITOR',
  MEDIA: "MEDIA",
  LINK: "LINK",
  MULTI: "MULTI",
  FORM: "FORM",
  METAVERSE: 'METAVERSE',
}


export const METAVERSE_DATA = [
  {
    label: "Stickball Mall",
    value: UNITY_LINK + "mall"
  },
  {
    label: "Stickball Show Room",
    value: UNITY_LINK + "showroom"
  },
  {
    label: "Financial Advisor",
    value: UNITY_LINK + "financial-advisor"
  },
  {
    label: "Stickball Grocery Store",
    value: UNITY_LINK + "grocery-store"
  },
  {
    label: "Doctor Office",
    value: UNITY_LINK + "doctor-office"
  },
  {
    label: "Hospital",
    value: UNITY_LINK + "hospital"
  },
  {
    label: "Human Resources",
    value: UNITY_LINK + "human-resources"
  },
  {
    label: "Grocery Stock",
    value: UNITY_LINK + "grocery-stock"
  },
  {
    label: "Personal Banking",
    value: UNITY_LINK + "personal-banking"
  },
  {
    label: "Banking Roles",
    value: UNITY_LINK + "banking-roles"
  },
  {
    label: "First Tech",
    value: UNITY_LINK + "first-tech"
  },
  {
    label: "Stickball Bank",
    value: UNITY_LINK + "stickball-bank"
  },
  {
    label: "Fidelity Investments",
    value: UNITY_LINK + "fidelity-investments"
  },
  {
    label: "Stickball Company",
    value: UNITY_LINK + "stickball-company"
  },
  {
    label: "Musketeers Mall",
    value: UNITY_LINK + "musketeers-mall"
  },
  {
    label: "Stickball Apartments",
    value: UNITY_LINK + "stickabll-apartments"
  },
  {
    label: "Smart Shoping Mall",
    value: UNITY_LINK + "smart-shop-mall"
  },
  {
    label: "Stickball Office",
    value: UNITY_LINK + "polygon-office"
  },
  {
    label: "Stickball School",
    value: UNITY_LINK + "school"
  },
  {
    label: "Careers",
    value: UNITY_LINK + "careers"
  },
  {
    label: "Stickball Business",
    value: UNITY_LINK + "stickball-business"
  },
  {
    label: "Jobs",
    value: UNITY_LINK + "jobs"
  },
  {
    label: "Investing",
    value: UNITY_LINK + "investing"
  },
  {
    label: "Job Performance",
    value: UNITY_LINK + "job-performance"
  },
  {
    label: "Brookline Bank",
    value: UNITY_LINK + "brookline-bank"
  },
  {
    label: "Passumpsic Bank",
    value: UNITY_LINK + "passumpsic-bank"
  },
  {
    label: "St. Vincents Thrift",
    value: UNITY_LINK + "vincents-thrift"
  },
  {
    label: "Fast Food Cashier",
    value: UNITY_LINK + "fast-food"
  },
  {
    label: "Save Mart",
    value: UNITY_LINK + "save-mart"
  },
  {
    label: "Direct to Work Program",
    value: UNITY_LINK + "direct-to-work-program"
  },
  {
    label: 'Healthy Relationship',
    value: UNITY_LINK + 'healthy-relationship',
  },
  {
    label: 'Personality Style',
    value: UNITY_LINK + 'personality-style',
  }
]