import React from 'react';
import { Box, keyframes } from '@mui/material';
import { styled } from '@mui/material/styles';

const swipeAnimation = keyframes`
  0% {
    transform: translateX(0);
    opacity: 0;
  }
    10% {
    transform: translateX(-10px);
    opacity: 0.5;
  }
    20% {
    transform: translateX(-40px);
    opacity: 0.7;
  }
    30% {
    transform: translateX(-70px);
    opacity: 0.9;
  }
    40% {
    transform: translateX(-100px);
    opacity: 1;
  }
  50% {
    transform: translateX(-130px);
        opacity: 1;
  }
    60% {
    transform: translateX(-160px);
    opacity: 1;
  }
  70% {
    transform: translateX(-190px);
    opacity: 0.7;
  }
    80% {
    transform: translateX(-220px);
    opacity: 0.3;
  }
  90% {
    transform: translateX(-250px);
    opacity: 0.1;
  }
  100% {
    transform: translateX(-280px);
    opacity: 0;
  }
`;

const SwipeIndicator = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: '30%',
    left: '70%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
    padding: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '10px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    animation: `${swipeAnimation} 1.5s ease-in-out infinite`,
    '&::after': {
        content: '"←"',
        fontSize: '24px',
        color: theme.palette.primary.main,
    }
}));

const SwipeHint = () => {
    return <SwipeIndicator />;
};

export default SwipeHint; 