import { Box, Button, Card, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  ADMIN_LINK,
  checkQuilValidation,
  DATA_TYPE,
  isAdminAdd,
  isAdminEdit,
  renderPlaceholder,
} from "../../../common/contentType/contentTypeData";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Adminwrapper } from "../../../common";
import { useStyles } from "../../../../utils/styles";
import TypographyWithMore from "../../../common/contentType/TypographyWithMore";
import ReactPlayer from "react-player";
import CustomCardWrapper from "../../../common/contentType/CustomCardWrapper";

const RenderYoutube = ({ content }) => {
  const { QUILL, LINK } = DATA_TYPE;
  const { type, name, description } = content?.allContent;
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: content?.allContent.content,
  });
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Video is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <CustomCardWrapper
      bgColor={theme.palette.primary.extraLight}
      position="relative"
    >
      {isAdminEdit() && (
        <Button
          className={classes.btnUpdateStyle}
          onClick={contentFormik.handleSubmit}
        >
          Update
        </Button>
      )}
      {isAdminAdd() && (
        <Button
          className={classes.btnAddStyle}
          onClick={contentFormik.handleSubmit}
        >
          ADD
        </Button>
      )}

      <Grid container spacing={2} alignItems="center" height="100%">
        <Grid item xs={12} md={6}>
          <Adminwrapper
            errorText={contentFormik.errors.name}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="name"
          >
            {renderPlaceholder(data?.name, "Enter Name")}
            <TypographyWithMore
              variant="h2"
              color="primary"
              data={data?.name}
              textLength={10}
              lines={2}
              textAlign="left"
              width="100%"
            />
          </Adminwrapper>

          <Adminwrapper
            errorText={contentFormik.errors.description}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="description"
          >
            {renderPlaceholder(data?.description, "Enter Description")}
            <TypographyWithMore
              variant="body1"
              color="text"
              data={data?.description}
              textLength={50}
              lines={5}
              textAlign="left"
              width="100%"
            />
          </Adminwrapper>
        </Grid>
        <Grid item xs={12} md={6} mt={{ xs: '1rem', md: '' }} display='flex' justifyContent='center' alignItems='center' height='80%'>
          <Adminwrapper
            errorText={contentFormik.errors.content}
            dataType={LINK}
            data={data}
            setData={setData}
            type="content"
            label={"Youtube Link"}
          >
            <ReactPlayer
              url={data?.content}
              playing={isMobile ? false : true}
              loop={true}
              controls={true}
              width='100%'
            />
          </Adminwrapper>
        </Grid>
      </Grid>
      <Card
        sx={{
          backgroundColor: theme.palette.primary.main,
          borderRadius: "0rem 0rem 1.8rem 1.8rem",
          boxShadow: "none",
          width: "100%",
          p: "1rem",
          position: "absolute",
          bottom: 0,
          left: 0
        }}
      >
        <Typography variant="h5" fontWeight='600' color={theme.palette.common.white} textAlign='center'>Check out this cool video to see how it works!</Typography>
      </Card>
    </CustomCardWrapper>
  );
};

export default RenderYoutube;
