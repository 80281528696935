import React, { useEffect } from "react";
import { Box, styled } from "@mui/material";

const PlayerContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "800px",
  borderRadius: "12px",
  overflow: "hidden",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  position: "relative",
}));

const VideoContainer = styled(Box)({
  position: "relative",
  paddingTop: "56.25%",
  "& iframe": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: "none",
  },
});

const CustomVideoPlayer = ({ linkId }) => {
  const embedUrl =
    `https://www.youtube-nocookie.com/embed/${linkId}?` +
    "controls=1&" + // Show video controls
    "rel=0&" + // Hide related videos
    "fs=0&" + // Disable fullscreen
    "iv_load_policy=3&" + // Hide video annotations
    "disablekb=1&" + // Disable keyboard controls
    "start=0&" + // Start from beginning
    "enablejsapi=0&" + // Disable JavaScript API
    "cc_load_policy=0&" + // Hide closed captions by default
    "origin=localhost&" + // Restrict origin
    "playsinline=1"; // Play inline on mobile

  return (
    <PlayerContainer>
      <VideoContainer>
        <iframe
          src={embedUrl}
          title="Video Player"
          width="728"
          height="410"
          frameBorder="0"
          allow="accelerometer"
          allownetworking="internal"
          sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
          style={{
            borderRadius: "8px",
          }}
        />
        <style>{`
          /* Target specific YouTube elements */
          iframe[src*="youtube"] {
            isolation: isolate;
          }

          /* Hide YouTube logo and branding */
          .ytp-button.ytp-youtube-button,
          .ytp-youtube-button,
          .ytp-watermark,
          .ytp-small-mode .ytp-youtube-button,
          .ytp-title,
          .ytp-show-cards-title,
          .ytp-cards-button,
          .ytp-pause-overlay,
          .ytp-gradient-top,
          .ytp-gradient-bottom,
          .ytp-chrome-top,
          .ytp-chrome-top-buttons,
          .ytp-impression-link,
          .ytp-pause-overlay-container,
          .ytp-contextmenu,
          .ytp-embed,
          .ytp-embed-title,
          .branding-img,
          .branding-img-container,
          .annotation,
          .video-annotations,
          .ytp-ce-element,
          .ytp-iv-player-content,
          .ytp-cards-teaser,
          .ytp-cards-button-icon,
          .ytp-cards-button-icon-shopping {
            opacity: 0 !important;
            display: none !important;
            width: 0 !important;
            height: 0 !important;
            pointer-events: none !important;
          }

          /* Hide title and info overlay */
          .ytp-gradient-top,
          .ytp-chrome-top,
          .ytp-title-text,
          .ytp-title-channel,
          .ytp-title-channel-logo {
            display: none !important;
            opacity: 0 !important;
          }

          /* Modify control bar */
          .ytp-chrome-controls {
            background: rgba(0, 0, 0, 0.5) !important;
          }

          /* Hide right click menu */
          .ytp-contextmenu,
          .ytp-popup {
            display: none !important;
          }

          /* Remove clickthrough */
          .ytp-click-through-overlay {
            display: none !important;
            pointer-events: none !important;
          }

          /* Hide end screen elements */
          .ytp-ce-element,
          .ytp-endscreen-content,
          .ytp-endscreen-previous,
          .ytp-endscreen-next {
            display: none !important;
          }

          /* Hide video suggestions overlay */
          .ytp-pause-overlay,
          .ytp-related-videos-overlay {
            display: none !important;
          }

          /* Hide any remaining branding */
          [class*="ytp-youtube"],
          [class*="ytp-logo"],
          [class*="ytp-watermark"],
          [class*="branding"],
          [class*="annotation"] {
            display: none !important;
            opacity: 0 !important;
            pointer-events: none !important;
          }

          /* Ensure only essential controls are visible */
          .ytp-play-button,
          .ytp-time-display,
          .ytp-progress-bar-container,
          .ytp-volume-panel {
            display: block !important;
            pointer-events: auto !important;
          }

          /* Remove extra padding from control bar */
          .ytp-chrome-bottom {
            padding-right: 12px !important;
          }

          /* Hide any remaining interactive elements */
          .ytp-ce-expanding-overlay,
          .ytp-ce-playlist-count,
          .ytp-ce-expanding-image,
          .ytp-ce-channel-title,
          .ytp-ce-video-title {
            display: none !important;
          }
        `}</style>
      </VideoContainer>
    </PlayerContainer>
  );
};

export default CustomVideoPlayer;

const PlayerWrapper = styled(Box)({
  width: "100%",
  maxWidth: "325px",
  minWidth: "325px",
  position: "relative",
  overflow: "hidden",
  borderRadius: "8px", // Add border radius to wrapper
  height: "770px",
  WebkitOverflowScrolling: "touch", // Add fixed height

  "& iframe": {
    border: "none",
    width: "100%",
    height: "100%", // Change to 100%
    overflow: "hidden",
    display: "block", // Add display block
    position: "absolute", // Add absolute positioning
    top: 0,
    left: 0,
    scrolling: "no",
  },
 
});

export const TiktokVideoPlayer = ({ videoUrl }) => {
  //   useEffect(() => {
  //     // Reload TikTok embed script
  //     const script = document.createElement('script');
  //     script.src = "https://www.tiktok.com/embed.js";
  //     script.async = true;
  //     document.body.appendChild(script);

  //     return () => {
  //       document.body.removeChild(script);
  //     };
  //   }, []);


  // const embedUrl =
  //   `https://www.tiktok.com/embed/v2/${videoId}?` +
  //   "music_info=0&" + // Hide music info
  //   "description=0&" + // Hide description
  //   "rel=0&" + // Hide related videos
  //   "native_context_menu=0&" + // Hide context menu
  //   "closed_caption=0&" + // Hide closed caption
  //   "controls=1&" + // Show video controls
  //   "progress_bar=1&" + // Show progress bar
  //   "play_button=1&" + // Show play button
  //   "volume_control=1&" + // Show volume control
  //   "fullscreen_button=0&" + // Show fullscreen button
  //   "timestamp=0&" + // Show timestamp
  //   "loop=1&" + //  loop video
  //   "autoplay=0"; // Don't autoplay

  return (
    <PlayerWrapper>
      <iframe
        sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
        src={videoUrl}
        allow="accelerometer"
        allownetworking="internal"
       
      />

      <style>{`
        /* Hide TikTok branding and social elements */
        .tiktok-embed-footer,
        .tiktok-embed-header,
        .tiktok-embed-actions,
        .tiktok-embed-share,
        .tiktok-embed-follow,
        .tiktok-embed-like,
        .tiktok-embed-comment,
        .tiktok-embed-music,
        .tiktok-embed-desc,
        .tiktok-feed,
        .tiktok-embed-video-info,
        .tiktok-embed-video-actions,
        .tiktok-embed-video-meta,
        .tiktok-embed-video-detail,
        .tiktok-embed-user-info,
        [class*="share-"],
        [class*="follow-"],
        [class*="profile-"],
        [class*="avatar-"],
        [class*="username-"],
        [class*="action-"],
        [class*="social-"] {
          display: none !important;
          opacity: 0 !important;
          visibility: hidden !important;
        }
 /* Hide related videos */
        .tiktok-embed-feed,
        .tiktok-embed-suggestions,
        .tiktok-embed-video-list,
        [class*="related-"],
        [class*="feed-"],
        [class*="suggestion-"] {
          display: none !important;
        }

        /* Keep only video player */
        .tiktok-embed,
        .tiktok-embed-video {
          margin: 0 !important;
          padding: 0 !important;
        }
        /* Remove watermark */
        .tiktok-embed-watermark,
        [class*="watermark"] {
          display: none !important;
        }

        /* Ensure video container takes full space */
        .tiktok-embed-video-container {
          padding: 0 !important;
          margin: 0 !important;
        }

        /* Hide end screen */
        .tiktok-embed-video-end-screen,
        [class*="end-screen"] {
          display: none !important;
        }

        /* Remove any overlays */
        .tiktok-embed-video-overlay,
        [class*="overlay"] {
          display: none !important;
        }
      `}</style>
    </PlayerWrapper>
  );
};
