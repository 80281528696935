import React from 'react'

export default function NewbieIcon({ color }) {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_70_3)">
                <path d="M9.02881 9.17108C10.1291 9.17108 11.021 8.27915 11.021 7.17889C11.021 6.07864 10.1291 5.18671 9.02881 5.18671C7.92855 5.18671 7.03662 6.07864 7.03662 7.17889C7.03662 8.27915 7.92855 9.17108 9.02881 9.17108Z" fill={color} />
                <path d="M15.1342 9.13904L14.3506 8.62445L12.6147 11.2677L9.76161 10.3104C9.53202 10.2301 9.28543 10.1866 9.0288 10.1866C8.3378 10.1866 7.7193 10.5031 7.31061 10.9986L7.30999 10.9981L5.35921 13.2629L3.27855 11.6588L2.70618 12.4013L5.49005 14.5475L6.80227 13.0241V16.7177H11.2554V12.4132C11.2554 12.189 11.2218 11.9726 11.1598 11.7684L13.0017 12.3864L15.1342 9.13904Z" fill={color} />
                <path d="M11.7646 2.69876C11.7646 2.64963 11.768 2.60051 11.7747 2.5526C11.8476 2.03051 12.3019 1.6437 12.8299 1.65541C13.3739 1.66638 13.8221 2.10001 13.8502 2.6426C13.8652 2.93232 13.7639 3.20729 13.5649 3.41695C13.3657 3.62676 13.0969 3.74229 12.8081 3.74229H12.3394V5.00166H13.2769V4.62295C13.6434 4.5331 13.9817 4.33951 14.2448 4.06245C14.6226 3.66451 14.815 3.14298 14.7865 2.59401C14.733 1.56295 13.8819 0.738946 12.8488 0.718102C11.8451 0.698352 10.9847 1.43088 10.8462 2.42288C10.8336 2.51351 10.8271 2.60635 10.8271 2.69876H11.7646Z" fill={color} />
                <path d="M12.3395 5.93927H13.277V6.87677H12.3395V5.93927Z" fill={color} />
                <path d="M5.17256 8.36589C5.17256 8.41486 5.16919 8.46408 5.16247 8.51205C5.08962 9.03414 4.6374 9.42014 4.10728 9.40924C3.56328 9.39827 3.11509 8.96464 3.08694 8.42205C3.0719 8.13233 3.17325 7.85736 3.37231 7.64771C3.5715 7.43789 3.84025 7.32236 4.12903 7.32236H4.59778V6.06299H3.66028V6.44171C3.29381 6.53155 2.95544 6.72518 2.6924 7.00224C2.31459 7.40014 2.12222 7.92168 2.15072 8.47064C2.20422 9.50171 3.05534 10.3257 4.0884 10.3466C5.07419 10.3466 5.95437 9.62043 6.091 8.64158C6.10365 8.5508 6.11006 8.45805 6.11006 8.36593L5.17256 8.36589Z" fill={color} />
                <path d="M3.66028 4.18793H4.59778V5.12543H3.66028V4.18793Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_70_3">
                    <rect width="16" height="16" fill="white" transform="translate(0.641113 0.717712)" />
                </clipPath>
            </defs>
        </svg>
    )
}
